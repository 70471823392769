import React, { Component } from 'react';
import './splashscreen.scss';
import Config from '../../data/siteconfig';
//import sHelpers from '../../modules/helpers/statichelpers';
//import * as QueryString from "query-string";
export default class splash extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            loading: false,
            fields: {},
            passwordShown: false,
        }
    }

    componentDidMount() {

        setTimeout(() => {
            this.props.history.push('/login');
        }, Config.minMsgTimeout);
        return false;
    }



    render() {
        return (
            <>
                <div className="body-scroll d-flex flex-column h-100 dark-bg">

                    <main className="container-fluid loader-wrap">
                        <div className="row h-100">
                            <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">
                                <div className="loader-cube-wrap loader-cube-animate mx-auto">
                                    <img src="/lucid-inventory.png" alt="Logo" />
                                </div>
                                <p className="mt-4">LUCID Inventory<br /><strong>Please wait...</strong></p>
                            </div>
                        </div>
                    </main>

                    {/* <main className="container-fluid h-100">
                        <div className="row h-100">
                            <div className="col text-center align-self-center">
                                <a href="landing.html" className="logo-splash">
                                    <div className="loader-cube-wrap loader-cube-animate mx-auto">
                                        <img src="/lucid-inventory.png" alt="Logo" />
                                    </div>
                                    <h2 className="text-white mt-4">Inventory App</h2>
                                </a>
                            </div>
                        </div>
                    </main> */}


                </div>
            </>
        )
    }
}
