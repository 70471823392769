import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import './header.scss';
import sHelpers from '../../modules/helpers/statichelpers';
// import { withAlert } from 'react-alert' // HOC to show the alert message
// import Config from '../../data/siteconfig';
// import Marquee from "react-fast-marquee";

const menuItems = [
    { menuId: 1, menuName: 'Dashboard', iconsName: 'bi bi-house-door', routePath: "/dashboard" },
    // { menuId: 2, menuName: 'My Orders', iconsName: 'fa fa-shopping-cart sidebar-icon', routePath: "/myorders" },
    // // { menuId: 2, menuName: 'My Bills', iconsName: 'fa fa-money', routePath: "/mybills" },
    // // { menuId: 3, menuName: 'Contact Us', iconsName: 'fa fa-phone sidebar-icon', routePath: "/home" },
    { menuId: 2, menuName: 'Logout', iconsName: 'bi bi-box-arrow-right', routePath: "/login" },

];

export default class SidebarMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            active: "dashboard"
        }
    }
    _handleClick(name) {
        this.setState({ active: name });
    }
    gotoLogin() {
        this.props.history.push('/login');
    }
    render() {
        //  const activeStyle = { color: '#007bff' };
        return (
            <>
                <div className="sidebar-wrap  sidebar-pushcontent">

                    <div className="closemenu text-muted">Close Menu</div>
                    <div className="sidebar dark-bg">

                        <div className="row my-3">
                            <div className="col-12 ">
                                <div className="card shadow-sm bg-opac text-white border-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <figure className="avatar avatar-44 rounded-15">
                                                    <img src="user-white.png" alt="" />
                                                </figure>
                                            </div>
                                            <div className="col px-0 align-self-center">
                                                <p className="mb-1">{sHelpers.secureStorage.getItem("inv.user.name")}</p>
                                                <p className="text-muted size-12">{sHelpers.secureStorage.getItem("inv.user.role.name")}</p>
                                            </div>
                                            {/* <div className="col-auto">
                                                <button className="btn btn-44 btn-light" onClick={this.gotoLogin.bind(this)}>
                                                    <i className="bi bi-box-arrow-right"></i>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills">
                                    {
                                        menuItems.map(mnuItem =>
                                            <li key={mnuItem.menuId} className="nav-item">

                                                <NavLink
                                                    className={this.state.active === mnuItem.menuName ? "nav-link" : "nav-link"}
                                                    onClick={this._handleClick.bind(this, mnuItem.menuName)}
                                                    to={mnuItem.routePath}
                                                >
                                                    <div className="avatar avatar-40 rounded icon"><i className={mnuItem.iconsName}></i></div>
                                                    <div className="col">{mnuItem.menuName}</div>
                                                    <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                </NavLink>

                                            </li>
                                        )}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
