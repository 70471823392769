import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css'; // Import CSS
import './daterange.scss'
//import moment from 'moment';
//import sHelpers from '../../modules/helpers/statichelpers';
const DateRangePicker = ({ startDateValue, endDateValue, onStartDatesChange, onEndDatesChange }) => {
    // console.log(startDateValue);
    //  console.log(endDateValue);
    //  const sDateValue  = !startDateValue ? maxDate : startDateValue
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    //  const [focusedInput, setFocusedInput] = useState(null);
    // const [fromDate, setFromDate] = useState(fromDateValue || null);
    // const [toDate, setToDate] = useState(toDateValue || null);
    //const reportDate = moment(sHelpers.secureStorage.getItem('inv.max.run.date'), "DD/MM/YYYY"); // One day before today

    // const [startDate, setStartDate] = useState(!startDateValue ? maxDate : startDateValue);
    //  const [endDate, setEndDate] = useState(!endDateValue ? maxDate : endDateValue);
    const [focused, setStartFocused] = useState(false);
    const [toFocused, setEndFocused] = useState(false);
    // if (!startDateValue) {
    //     startDateValue = maxDate;
    //     // setStartDate(maxDate)
    // }
    // if (!endDateValue) {
    //     endDateValue = maxDate;
    //     //setStartDate(maxDate);
    // }

    //const minDate = moment().subtract(1, 'days'); // One day before today
    //const maxDate = moment().add(1, 'months'); // One month from today


    // useEffect(() => {
    //     //if (!startDateValue) startDateValue = maxDate;
    //     // console.log('Updating startDate:', startDateValue,);
    //     setStartDate(startDateValue);
    // }, [startDateValue]);

    // useEffect(() => {
    //     // console.log('Updating endDate:', endDateValue);
    //     setEndDate(endDateValue);
    // }, [endDateValue]);

    const handleStartDatesChange = (date) => {
        startDateValue = date;
        if (onStartDatesChange) {
            onStartDatesChange(date);
        }
    };
    const handleEndDatesChange = (date) => {
        endDateValue = date;
        if (onEndDatesChange) {
            onEndDatesChange(date);
        }
    };

    return (

        // <div className="date-picker-container">
        //     <DateRangePicker
        //         startDate={startDate}
        //         startDateId="start_date_id"
        //         endDate={endDate}
        //         endDateId="end_date_id"
        //         onDatesChange={({ startDate, endDate }) => {
        //             setStartDate(startDate);
        //             setEndDate(endDate);
        //         }}
        //         focusedInput={focusedInput}
        //         onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        //         displayFormat="MMM D, YYYY"
        //     />
        // </div>
        <div className='date-picker'>
            <div className='py-1 px-0'>
                <i className="bi bi-calendar-range size-18"></i>
            </div>
            <div className='from-date-picker form-floating mb-3'>

                <SingleDatePicker
                    date={startDateValue} // Moment object or null
                    onDateChange={handleStartDatesChange} // Handle date change
                    focused={focused} // Boolean to control focus
                    onFocusChange={({ focused }) => setStartFocused(focused)} // Handle focus change
                    id="from-date" // Unique ID for the input
                    numberOfMonths={1} // Number of months to show at once
                    isOutsideRange={() => false} // Define valid date range
                    displayFormat="DD-MM-YYYY" // Date format for display
                    placeholder='From Date'
                    readOnly
                // isOutsideRange={day =>
                //     day.isAfter(reportDate) // Limit date selection
                // }
                //onDatesChange={handleDatesChange}
                />
            </div>

            <div className='to-date-picker form-floating mb-3'>
                <SingleDatePicker
                    date={endDateValue} // Moment object or null
                    onDateChange={handleEndDatesChange} // Handle date change
                    focused={toFocused} // Boolean to control focus
                    onFocusChange={({ focused }) => setEndFocused(focused)} // Handle focus change
                    id="to-date" // Unique ID for the input
                    numberOfMonths={1} // Number of months to show at once
                    isOutsideRange={() => false} // Define valid date range
                    displayFormat="DD-MM-YYYY" // Date format for display
                    placeholder='To Date'
                    readOnly
                // isOutsideRange={day =>
                //     //day.isBefore(minDate) || day.isAfter(maxDate) // Limit date selection
                //     day.isAfter(reportDate)
                // }
                />

            </div>
        </div>

    );
};

export default DateRangePicker;
