import React, { Component } from 'react';
// import loading_animation from './loading-bars.svg'
// import styles from './loading.module.sass'
export default class PageLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeOut: false
    };
  }
  componentDidMount() {
    // Use setTimeout to delay the hiding of the loader
    this.fadeOutTimeout = setTimeout(() => {
      this.setState({ fadeOut: true });
    }, 800);
  }

  componentWillUnmount() {
    // Clear the timeout if the component unmounts before the timeout completes
    if (this.fadeOutTimeout) {
      clearTimeout(this.fadeOutTimeout);
    }
  }

  render() {
    const { fadeOut } = this.state;
    return (
      <>
        <div className="container-fluid">
          <div className={fadeOut ? "loader-wrap fade-out" : "loader-wrap"} style={{ display: fadeOut ? "none" : "block" }} >
            <div className="row h-100">
              <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">
                <div className="loader-cube-wrap loader-cube-animate mx-auto">
                  <img src="lucid-inventory.png" alt="Logo" />
                </div>
                <p className="mt-4">Inventory<br /><strong>Please wait...</strong></p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
