import React, { Component, createRef } from "react";
import { withAlert } from "react-alert"; // HOC to show the alert message
import Footer from "../../../component/footer";
import PageLoading from "../../../component/loadinganimation/pageloading";
import LoadingAnimation from "../../../component/loadinganimation/eventloader";
import fetching_animation from '../../../component/loadinganimation/bouncing-circles.svg';
import Config, { lazyLoading } from "../../../data/siteconfig";
import sHelpers from "../../../modules/helpers/statichelpers";
import DateRangePicker from "../../../component/daterangepicker/daterange";
import moment from "moment";
class interdepartmenttransfer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			paddingTop: 0,
			paddingBottom: 0,
			propertyList: [
			],
			departmentList: [],
			toDepartmentList: [],
			fields: {},
			startDate: null,
			endDate: null,
			selectedPropertyCode: 0,
			selectedPropertyName: "",
			selectedDepartmentCode: 0,
			selectedDepartmentName: "",
			selectedToDepartmentCode: 0,
			selectedToDepartmentName: "",
			resultData: [],
			dashboardData: [],
			//Lazy loading
			pagenumber: 1,
			isLoading: false, // Controls loading animation
			isScrollEnabled: true, // Enables or disables further loading
			//End lazy loading
		};
		this.mainRef = createRef();
		this.headerRef = createRef();
		this.footerRef = createRef();
	}

	updateStyles = () => {
		const windowHeight = window.innerHeight;
		const headerHeight = this.headerRef.current
			? this.headerRef.current.offsetHeight
			: 0;
		const footerHeight = this.footerRef.current
			? this.footerRef.current.offsetHeight
			: 0;

		if (this.mainRef.current) {
			this.mainRef.current.style.minHeight = `${windowHeight}px`;
			this.setState({
				paddingTop: headerHeight + 10,
				paddingBottom: footerHeight + 10,
			});
		}
	};

	componentDidMount() {
		this.updateStyles();
		window.addEventListener("resize", this.updateStyles);
		if (!sHelpers.LoggedinInputValidation()) {
			this.props.alert.error("Session Expired! Please log in again.");
			this.setState({ loading: true });
			setTimeout(() => {
				this.props.history.push("/login");
			}, Config.minMsgTimeout);
			return false;
		}

		this.setState({
			propertyList: sHelpers.secureStorage.getItem("inv.user.properties"),
		});
		// Enable scroll listener for lazy loading
		window.addEventListener('scroll', this.handleScroll);
		this.handleFilterClick(this);

		//moment.locale('en-gb');
	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateStyles);
		window.removeEventListener("scroll", this.handleScroll);
	};

	handleChangeProperty(event) {
		event.preventDefault();
		const reportDate = moment(
			moment(sHelpers.secureStorage.getItem("inv.max.run.date"), "DD/MM/YYYY").subtract(1, "days"),
			"DD/MM/YYY"
		);
		// setSelectedValue(event.target.value);
		// const _propertyList = sHelpers.secureStorage.getItem("inv.user.properties");
		// const _filterStore = sHelpers.secureStorage.getItem("inv.user.stores").filter(s => s.PropertyCode === Number(event.target.value))
		const _filterproperty = sHelpers.secureStorage
			.getItem("inv.user.properties")
			.filter((p) => p.PropertyCode === Number(event.target.value));
		// Filter department list based on selected property code
		const _filteredDepartments = sHelpers.secureStorage
			.getItem("inv.user.departments")
			.filter((d) => d.PropertyCode === Number(event.target.value));

		this.setState(
			{
				loading: true,
				pagenumber: 1,
				startDate:
					_filterproperty.length > 0
						? moment(_filterproperty[0].RunDate, "DD/MM/YYYY")
						: reportDate,
				endDate:
					_filterproperty.length > 0
						? moment(_filterproperty[0].RunDate, "DD/MM/YYYY")
						: reportDate,
				selectedPropertyCode: event.target.value,
				selectedPropertyName:
					_filterproperty.length > 0 ? _filterproperty[0].PropertyName : "",
				selectedDepartmentCode: _filteredDepartments.length > 0 ? _filteredDepartments[0].DepartmentCode : "",
				selectedDepartmentName: _filteredDepartments.length > 0 ? _filteredDepartments[0].DepartmentName : "",

				selectedToDepartmentCode: _filteredDepartments.length === 0 ? "" : this.state.selectedToDepartmentCode,
				selectedToDepartmentName: _filteredDepartments.length === 0 ? "" : this.state.selectedToDepartmentName,
				departmentList: _filteredDepartments,
				toDepartmentList: _filteredDepartments.length === 0 ? [] : this.state.toDepartmentList // Clear toDepartmentList if no departments found		
			},
			() => {
				// This callback will be called once the state update is complete
				// console.log('State updated:', this.state);
			}
		);
		setTimeout(() => {
			this.setState({ loading: false });
		}, 1000);
	};

	handleChangeFromDepartment(event) {
		event.preventDefault();
		// Filter department list based on selected property code
		const _filteredDepartments = sHelpers.secureStorage
			.getItem("inv.user.departments")
			.filter((d) => d.PropertyCode === Number(event.target.value));

		// Filter the department list to exclude the selected "From Department"
		const filteredToDepartments = this.state.departmentList.filter(
			(d) => d.DepartmentCode !== Number(event.target.value)
		);
		this.setState(
			{
				loading: true,
				pagenumber: 1,
				selectedDepartmentCode: event.target.value,
				selectedDepartmentName:
					_filteredDepartments.length > 0 ? _filteredDepartments[0].DepartmentName : "",
				toDepartmentList: filteredToDepartments,  // Update the filtered "To Department" list
			},
			() => {
				// This callback will be called once the state update is complete
				// console.log('State updated:', this.state);
			}
		);
		setTimeout(() => {
			this.setState({ loading: false });
		}, 1000);
	};

	handleChangeToDepartment(event) {
		const selectedToDepartmentCode = event.target.value; // Get the selected department code
		// Find the department name based on the selected department code
		const selectedDepartment = this.state.departmentList.find(
			(d) => d.DepartmentCode === Number(selectedToDepartmentCode)
		);
		// Update state with the selected department code and department name
		this.setState({
			selectedToDepartmentCode: selectedToDepartmentCode, // Update state with selected DepartmentCode
			selectedToDepartmentName: selectedDepartment ? selectedDepartment.DepartmentName : "", // If found, set the name; otherwise, set an empty string
			pagenumber: 1,
		}, () => {
			// Log after the state is updated
			console.log("Selected To Department:", this.state.selectedToDepartmentName);
		});
	};

	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	};

	handleStartDatesChange = (date) => {
		this.setState({ startDate: date });
	};

	handleEndDatesChange = (date) => {
		this.setState({ endDate: date });
	};

	validateDateRange(fromDate, toDate) {
		var fromdate = new Date(fromDate);
		var todate = new Date(toDate);
		var timeDiff = Math.abs(todate.getTime() - fromdate.getTime());
		var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Difference in days
		return diffDays <= 30;
	};

	handleFilterClick = async (appendData = false) => {
		try {
			const reportDate = moment(
				moment(sHelpers.secureStorage.getItem("inv.max.run.date"), "DD/MM/YYYY").subtract(1, "days"),
				"DD/MM/YYY"
			); // One day before today
			//const reportDate = moment(sHelpers.secureStorage.getItem('inv.max.run.date'), "DD/MM/YYYY");
			if (this.state.startDate && this.state.endDate) {
				const fromMoment = moment(this.state.startDate);
				const toMoment = moment(this.state.endDate);
				if (fromMoment.isAfter(toMoment)) {
					this.props.alert.error("From Date must be less than To Date.");
					return false;
				}
			} else if (this.state.startDate && !this.state.endDate) {
				this.props.alert.error("Invalid To Date");
				return false;
			} else if (!this.state.startDate && this.state.endDate) {
				this.props.alert.error("Invalid From Date");
				return false;
			} else if (!this.state.startDate && !this.state.endDate) {
				this.setState({ startDate: reportDate, endDate: reportDate });
			}

			let fields = this.state.fields;
			this.setState({ btnDisabled: true });

			const formatStartDate = this.state.startDate
				? this.state.startDate
				: reportDate;
			const formatEndDate = this.state.endDate
				? this.state.endDate
				: reportDate;

			if (!this.state.fields["interdepartmenttransferno"]) {
				// Added by vijay on 25-09-2024
				if (!this.validateDateRange(formatStartDate, formatEndDate)) {
					this.props.alert.error("Date range must be within 30 days .");
					//this.setState({ resultData: [] });
					this.setState({ btnDisabled: false });
					return false;
				}
			}
			//console.log('from', formatStartDate);
			//console.log('to', formatEndDate);
			// Clear resultData if appendData is false (filter button clicked)
			if (!appendData) {
				this.setState({ resultData: [] });
			}
			// Set loading state
			this.setState({ isLoading: true });
			const currentPageNo = this.state.pagenumber;
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);
			let parameter =
				"?login=" +
				sHelpers.secureStorage.getItem("inv.user.name") +
				"&pagenumber=" +
				currentPageNo +
				"&noofrecords=" +
				lazyLoading.NoOfRecords;
			parameter +=
				"&IsMainUser=" + sHelpers.secureStorage.getItem("inv.ismain.user");
			parameter +=
				"&rightspropertycode=" +
				sHelpers.secureStorage.getItem("inv.rights.property.code");

			if (fields["interdepartmenttransferno"])
				parameter += "&interdepartmenttransferno=" + fields["interdepartmenttransferno"];
			if (formatStartDate)
				parameter += "&fromdate=" + formatStartDate.format("YYYY-MM-DD");
			if (formatEndDate)
				parameter += "&todate=" + formatEndDate.format("YYYY-MM-DD");
			if (this.state.selectedPropertyCode > 0)
				parameter += "&propertycode=" + this.state.selectedPropertyCode;
			if (this.state.selectedDepartmentCode > 0)
				parameter += "&fromdepartmentcode=" + this.state.selectedDepartmentCode;
			if (this.state.selectedToDepartmentCode > 0)
				parameter += "&todepartmentcode=" + this.state.selectedToDepartmentCode;

			//console.log(parameter);

			await fetch(Config.baseAPIUrl + "store/interdepartmenttransfers" + parameter, {
				method: "GET",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((response) => {
					// if (response.IsSuccessful) {
					// 	// this.props.alert.success(response.Message);
					// 	const newData = response.InterDepartmentTransfer;
					// 	this.setState((prevState) => ({
					// 		resultData: appendData ? [...prevState.resultData, ...newData] : newData,
					// 		dashboardData: response.Dashboard,
					// 	}));

					// 	setTimeout(() => {
					// 		this.setState({
					// 			isLoading: false,
					// 		});
					// 	}, 2000);

					// 	if (newData.length < lazyLoading.NoOfRecords) {
					// 		this.setState({ isScrollEnabled: false }); // Disable scrolling if no more records
					// 	} else {
					// 		this.setState({ isScrollEnabled: true }); // Enable if more records are available
					// 	}
					// } else {
					// if (response.Message) {
					// 	this.props.alert.error(response.Message);
					// 	this.setState({
					// 		resultData: [],
					// 		dashboardData: response.Dashboard,
					// 	});
					// 	this.setState({ isScrollEnabled: false, isLoading: false });
					// } else {
					// 	this.props.alert.error(response[0]);
					// }

					// }
					if (response) {
						const dataKitchenWastage = response.InterDepartmentTransfer;
						const { pagenumber } = this.state;
						if (pagenumber === 1 && dataKitchenWastage.length === 0) {
							if (response.Message) {
								this.props.alert.error(response.Message);
								this.setState({
									resultData: [],
									dashboardData: response.Dashboard,
								});
								this.setState({ isScrollEnabled: false, isLoading: false });
								this.props.history.push("/interdepartmenttransfer");
							} else {
								this.props.alert.error(response[0]);
								this.props.history.push("/interdepartmenttransfer");
							}
						}
						else {
							// this.props.alert.success(response.Message);
							const newData = response.InterDepartmentTransfer;
							this.setState((prevState) => ({
								resultData: appendData ? [...prevState.resultData, ...newData] : newData,
								dashboardData: response.Dashboard,
							}));

							setTimeout(() => {
								this.setState({
									isLoading: false,
								});
							}, 2000);

							if (newData.length < lazyLoading.NoOfRecords) {
								this.setState({ isScrollEnabled: false }); // Disable scrolling if no more records
							} else {
								this.setState({ isScrollEnabled: true }); // Enable if more records are available
							}
						}
					}
					this.setState({ btnDisabled: false });
				})
				.catch((err) => {
					console.log(err);
					this.setState({ loading: false });
					this.setState({ btnDisabled: false });
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			this.setState({ loading: false });
			this.props.alert.error(error.message);
			throw error;
		}
	};

	handleScroll = () => {
		const { isLoading, isScrollEnabled, pagenumber } = this.state;
		if (
			isScrollEnabled &&
			!isLoading &&
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 100
		) {
			this.setState({ isLoading: true }, () => {
				this.loadMoreData(pagenumber + 1);
			});
		}
	};

	loadMoreData = async (nextPageNumber) => {
		this.setState({ pagenumber: nextPageNumber });
		if (this.state.pagenumber > 1) {
			await this.handleFilterClick(true); // Append data
		} else {
			this.setState({ isLoading: false });
		}
	};

	handleClearClick = () => {
		const reportDate = moment(
			moment(
				sHelpers.secureStorage.getItem("inv.max.run.date"),
				"DD/MM/YYYY"
			).subtract(1, "days"),
			"DD/MM/YYY"
		); // One day before today
		let fields = this.state.fields;
		fields["interdepartmenttransferno"] = "";
		this.setState({ fields });
		this.setState({ pagenumber: 1, loading: true });
		this.setState({ startDate: reportDate, endDate: reportDate });
		setTimeout(() => {
			this.handleFilterClick(this);
			this.setState({ loading: false });
		}, Config.minMsgTimeout);
	};

	goBack() {
		this.props.history.push("/dashboard");
	};

	gotoEntry(interDepartmentTransferNo, mode) {
		this.props.history.push("/interdepartmenttransfer-entry?" + mode, {
			interDepartmentTransferNo: interDepartmentTransferNo,
			mode: mode,
		});

	};

	gotoPrint(interDepartmentTransferNo, mode) {
		this.props.history.push("/interdepartmenttransfer-print", {
			interDepartmentTransferNo: interDepartmentTransferNo,
			mode: mode,
		});
	};

	render() {
		const { paddingTop, paddingBottom } = this.state;
		const { startDate, endDate } = this.state;

		return (
			<>
				{this.state.loading && <LoadingAnimation />}
				<PageLoading />
				<main
					className="h-100"
					ref={this.mainRef}
					style={{
						paddingTop: `${paddingTop}px`,
						paddingBottom: `${paddingBottom}px`,
					}}
				>
					<header ref={this.headerRef} className="header position-fixed">
						<div className="row">
							<div className="col-auto">
								<button
									className="btn btn-light btn-44 back-btn"
									onClick={this.goBack.bind(this)}
								>
									<i className="bi bi-arrow-left"></i>
								</button>
							</div>
							<div className="col align-self-center text-center">
								<h5>Inter Department Transfer</h5>
							</div>
							{/* <div className="col-auto">
                    <a href="notifications.html" target="_self" className="btn btn-light btn-44">
                        <i className="bi bi-bell"></i>
                        <span className="count-indicator"></span>
                    </a>
                </div> */}
						</div>
					</header>

					<div className="main-container container">
						<div className="row mb-3">
							<div className="col">
								<h6 className="title">Transfer List</h6>
							</div>
							<div
								className="col-auto align-self-center"
								onClick={this.gotoEntry.bind(this, 0, "New")}
							>
								<button
									className="btn-default btn btn-sm shadow-sm avatar me-2 text-white w-100"
									id="addtohome"
									disabled={this.state.btnDisabled}
								>
									<i className="bi bi-plus me-2"></i> Add New
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6">
								<div className="card mb-4">
									<div className="card-header border-0">
										<div className="col-12 col-md-12">
											<div className="form-group form-floating  mb-3">
												<input
													type="text"
													className="form-control"
													placeholder="TransferNo No"
													id="interdepartmenttransfer-no"
													onChange={this.handleChange.bind(this, "interdepartmenttransferno")}
													value={this.state.fields["interdepartmenttransferno"]}
													maxLength="30"
												/>
												<label htmlFor="interdepartmenttransfer-remarks">Transfer No</label>
											</div>
										</div>
										<div className="row">
											<DateRangePicker
												startDateValue={startDate}
												endDateValue={endDate}
												onStartDatesChange={this.handleStartDatesChange}
												onEndDatesChange={this.handleEndDatesChange}
											/>
										</div>

										<div className="col-12 col-md-12">
											<div className="form-floating mb-3 is-valid">
												<select
													className="form-control"
													id="inter-dep-trans-properties"
													onChange={this.handleChangeProperty.bind(this)}
												>
													<option value="0" key="0">
														{" "}
														All
													</option>
													{this.state.propertyList.map((prop) => (
														<option
															value={prop.PropertyCode}
															key={prop.PropertyCode}
														>
															{prop.PropertyName}
														</option>
													))}
												</select>
												<label htmlFor="country">Property</label>
											</div>
										</div>


										<div className="col-12 col-md-12">
											<div className="form-floating mb-3 is-valid">
												<select
													className="form-control"
													id="from-department"
													onChange={this.handleChangeFromDepartment.bind(this)}
												>
													<option value="0" key="0">
														{" "}
														All
													</option>
													{this.state.departmentList.map((dep) => (
														<option
															value={dep.DepartmentCode}
															key={dep.DepartmentCode}
														>
															{dep.DepartmentName}
														</option>
													))}
												</select>
												<label htmlFor="country">From Department</label>
											</div>
										</div>


										<div className="col-12 col-md-12">
											<div className="form-floating mb-3 is-valid">
												<select
													className="form-control"
													id="from-department"
													onChange={this.handleChangeToDepartment.bind(this)}
												>
													<option value="0" key="0">
														{" "}
														All
													</option>
													{this.state.toDepartmentList.map((dep) => (
														<option
															value={dep.DepartmentCode}
															key={dep.DepartmentCode}
														>
															{dep.DepartmentName}
														</option>
													))}
												</select>
												<label htmlFor="country">To Department</label>
											</div>
										</div>

										<div className="row">
											<div className="col-6 col-md-6">
												<button
													className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
													disabled={this.state.btnDisabled}
													//onClick={this.handleFilterClick.bind(this)}
													onClick={() => this.handleFilterClick(false)}
												>
													<i className="bi bi-search me-2"></i> Filter
												</button>
											</div>
											<div className="col-6 col-md-6">
												<button
													className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
													disabled={this.state.btnDisabled}
													onClick={this.handleClearClick.bind(this)}
												>
													<i className="bi bi-arrow-counterclockwise me-2"></i>{" "}
													Clear
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-6">
								<div className="row">
									<div className="col-6 col-md-12">
										<div className="card shadow-sm mb-4">
											<div className="card-body">
												<div className="row">
													<div className="col-auto">
														<div className="circle-small">
															<div id="circleprogressone"></div>
															<div className="avatar avatar-30 alert-primary text-primary rounded-circle">
																<i className="bi bi-file-text-fill"></i>
															</div>
														</div>
													</div>
													<div className="col px-0 align-self-center">
														<p className="text-muted size-12 mb-0">
															Transfers
														</p>
														<p>
															{this.state.dashboardData
																? this.state.dashboardData.TotalInterDepartmentTransfer
																: 0}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* <div className="col-6 col-md-12">
                                        <div className="card shadow-sm mb-4">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="circle-small">
                                                            <div id="circleprogresstwo"></div>
                                                            <div className="avatar avatar-30 alert-success text-success rounded-circle">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                                                                    <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col px-0 align-self-center">
                                                        <p className="text-muted size-12 mb-0">Value</p>
                                                        <p>{this.state.dashboardData ? this.state.dashboardData.TotalAmount : 0}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col">
								{this.state.resultData.map((row) => (
									<div className="card mb-3">
										<div className="card-body">
											<div className="row">
												<div className="col-auto">
													<div className="avatar avatar-50 shadow rounded-10 ">
														<p>{row.InterDepartmentTransferDisplayNo}</p>
													</div>
												</div>
												<div className="col align-self-center ps-0">
													<p className="text-color-theme small mb-1 fw-medium">
														{row.PropertyName}
														{/* <br /> <span className="text-muted">request</span> */}
													</p>
													<p className="text-muted mb-0">
														Entered By:{" "}
														<span className="text-color-theme fw-medium">
															{row.EnterBy}
														</span>
													</p>
													<p>
														<span className="text-muted">{row.CreatedBy}</span>{" "}
														<small className="text-muted">
															{row.InterDepartmentTransferDateTime}
														</small>
													</p>
												</div>
												<div className="col-auto">
													<button
														className="btn btn-44 btn-light text-danger"
														onClick={this.gotoEntry.bind(
															this,
															row.InterDepartmentTransferNo,
															"View"
														)}
													>
														<i className="bi bi-eye"></i>
													</button>
													<button
														className="btn btn-44 btn-light btn-lg"
														onClick={this.gotoEntry.bind(
															this,
															row.InterDepartmentTransferNo,
															"Edit"
														)}
													>
														<i className="bi bi-pencil"></i>
													</button>
													<button
														className="btn btn-44 btn-light text-danger"
														onClick={this.gotoEntry.bind(
															this,
															row.InterDepartmentTransferNo,
															"Delete"
														)}
													>
														<i className="bi bi-trash"></i>
													</button>
													<button
														className="btn btn-44 btn-light btn-lg"
														onClick={this.gotoPrint.bind(
															this,
															row.InterDepartmentTransferNo,
															"Print"
														)}
													>
														<i className="bi bi-printer"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								))}
								{/* Conditional Rendering for Loader */}
								{this.state.isLoading && (
									<div id="page-record-loader" className="text-center loader-container">
										<img src={fetching_animation} alt="Loading..." width={50} height={50} />
										<span>   Fetching</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</main>

				<Footer footerRef={this.footerRef} />
			</>
		);
	}
}
export default withAlert()(interdepartmenttransfer);
