import React, { Component } from 'react';
// import './header.scss';
// import sHelpers from '../../modules/helpers/statichelpers';
// import { withAlert } from 'react-alert' // HOC to show the alert message
// import Config from '../../data/siteconfig';
// import Marquee from "react-fast-marquee";



export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            menuOpen: false
        }
    }

    toggleMenu = () => {
        this.setState(prevState => {
            const newMenuState = !prevState.menuOpen;

            if (newMenuState) {
                document.body.classList.add('menu-open');
                document.documentElement.classList.add('menu-open');
            } else {
                document.body.classList.remove('menu-open');
                document.documentElement.classList.remove('menu-open');
            }

            return { menuOpen: newMenuState };
        });

        // Prevent default behavior if necessary
        return false;
    };

    render() {

        return (
            <>
                <header ref={this.headerRef} className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a href="#" target="_self" className="btn btn-light btn-44 menu-btn" onClick={this.toggleMenu}>
                                <i className="bi bi-list"></i>
                            </a>
                        </div>
                        <div className="col align-self-center text-center">
                            <div className="logo-small">
                                <img src="lucid-inventory.png" alt="" />
                                <h5>Inventory</h5>
                            </div>
                        </div>
                        {/* <div className="col-auto">
                                <a href="notifications.html" target="_self" className="btn btn-light btn-44">
                                    <i className="bi bi-bell"></i>
                                    <span className="count-indicator"></span>
                                </a>
                            </div> */}
                    </div>
                </header>
            </>
        );
    }
}
