import React, { Component, createRef } from "react";
import { withAlert } from "react-alert"; // HOC to show the alert message
import Config, { entryMode } from "../../../data/siteconfig";
import LoadingAnimation from "../../../component/loadinganimation/eventloader";
import sHelpers from "../../../modules/helpers/statichelpers";
import "./interdepartmenttransfer.scss";
import "./autocomplete.scss";
import PageLoading from "../../../component/loadinganimation/pageloading";

class interdepartmenttransferentry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            paddingTop: 0,
            paddingBottom: 0,
            propertyList: [],
            storeList: [],
            departmentList: [],
            toDepartmentList: [],
            selectedPropertyCode: 0,
            selectedPropertyName: "",
            selectedStoreCode: 0,
            selectedStoreName: "",
            interDepartmentTransferDate: "",
            items: [],
            selectedItems: [],
            fields: {},
            btnDisabled: false,
            btnVisible: true,
            btnDeleteVisabled: false,
            ctrlDisabled: false,
            isEditMode: false,
            selectedItemCode: 0,
            selectedItemName: 0,
            selectedItemUnits: "",
            selectedItemRate: 0,
            filteredSuggestions: [],
            itemInputValue: "",
            selectedItemValue: "",
            mode: "-",
            selectedFromDepartmentCode: 0,
            selectedFromDepartmentName: "",
            selectedToDepartmentCode: 0,
            selectedToDepartmentName: "",
            typeLists: [
                { name: "Item", id: "1" },
                { name: "POS / Recipe Item", id: "2" },
                { name: "Ingredient / Sub Recipe", id: "3" },
            ],
            selectedTypeId: "1", // Set the default to "Item"
            selectedTypeName: "Item", // Set the default name to "Item"
            interDepartmentTransferDisplayNo: 0,
            showStoreList: true,
            interDepartmentTransferAmount: "",
            selectedRecipeCode: null
        };
        this.mainRef = createRef();
        this.headerRef = createRef();
        this.footerRef = createRef();
        this.itemSearchRef = React.createRef();
        this.qtyRef = React.createRef();
        this.enteredByRef = React.createRef();
        this.handleChangeFromDepartmentList = this.handleChangeFromDepartmentList.bind(this);
        this.handleChangeToDepartmentList = this.handleChangeToDepartmentList.bind(this);
    }

    updateStyles = () => {
        const windowHeight = window.innerHeight;
        const headerHeight = this.headerRef.current
            ? this.headerRef.current.offsetHeight
            : 0;
        const footerHeight = this.footerRef.current
            ? this.footerRef.current.offsetHeight
            : 0;

        if (this.mainRef.current) {
            this.mainRef.current.style.minHeight = `${windowHeight}px`;
            this.setState({
                paddingTop: headerHeight + 10,
                paddingBottom: footerHeight + 10,
            });
        }
    };

    componentDidMount() {
        this.updateStyles();
        window.addEventListener("resize", this.updateStyles);

        if (!sHelpers.LoggedinInputValidation()) {
            this.props.alert.error("Session Expired! Please log in again.");
            this.setState({ loading: true });
            setTimeout(() => {
                this.props.history.push("/login");
            }, Config.minMsgTimeout);
            return false;
        }

        if (!this.props.location.state) {
            setTimeout(() => {
                this.props.history.push("/interdepartmenttransfer");
            }, Config.minMsgTimeout);
        }


        this.setState({
            propertyList: sHelpers.secureStorage.getItem("inv.user.properties"),
        });

        if (sHelpers.secureStorage.getItem("inv.user.properties").length > 0) {
            let interDepartmentTransferDate = sHelpers.secureStorage.getItem(
                "inv.user.properties"
            )[0].RunDate;
            const _filterStore = sHelpers.secureStorage
                .getItem("inv.user.stores")
                .filter(
                    (s) =>
                        s.PropertyCode ===
                        sHelpers.secureStorage.getItem("inv.user.properties")[0]
                            .PropertyCode
                );

            this.setState({
                storeList: _filterStore,
                interDepartmentTransferDate: interDepartmentTransferDate,
                selectedPropertyCode: sHelpers.secureStorage.getItem(
                    "inv.user.properties"
                )[0].PropertyCode,
                selectedPropertyName: sHelpers.secureStorage.getItem(
                    "inv.user.properties"
                )[0].PropertyName,
            });

            if (_filterStore.length > 0) {
                this.setState({
                    selectedStoreCode: _filterStore[0].StoreCode,
                    selectedStoreName: _filterStore[0].StoreName,
                });
                this.getStoreItems(
                    sHelpers.secureStorage.getItem("inv.user.properties")[0].PropertyCode,
                    _filterStore[0].StoreCode
                );
            }
        }

        // if first property having departmentList
        const propertyList = sHelpers.secureStorage.getItem("inv.user.properties");

        if (propertyList && propertyList.length > 0) {
            this.setState({ propertyList }, () => {
                // Get the first property code after state update
                const firstPropertyCode = this.state.propertyList[0].PropertyCode;

                // Call handleChangeProperty with the first property code
                this.handleChangeProperty({ target: { value: firstPropertyCode } });
            });
        }

        if (this.props.location.state) {
            this.setState({ mode: this.props.location.state.mode });
            this.setState({
                interdepartmenttransferno: this.props.location.state.interDepartmentTransferNo,
            });
            setTimeout(() => {
                if (
                    this.props.location.state.mode === "View" ||
                    this.props.location.state.mode === "Delete" ||
                    this.props.location.state.mode === "Edit"
                ) {
                    this.getViewInterDepartmentTransferList(this.props.location.state.interDepartmentTransferNo);
                }
                this.handleMode();
            }, Config.minMsgTimeout);
        }

        //const { propertyList } = this.state;

        // handleMode
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateStyles);
    };

    getStoreItems = async (propertyCode, storeCode) => {
        try {
            var requestIP = "0.0.0.1";
            this.setState({ storeItems: [] });
            const encodedCredentials = btoa(
                `${Config.invAPIUserName}:${Config.invAPIPassword}`
            );

            await fetch(
                Config.baseAPIUrl +
                "common/storeIssueditems?propertycode=" +
                propertyCode +
                "&storecode=" +
                storeCode +
                "&requestip=" +
                requestIP,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Basic " + encodedCredentials,
                        "Content-type": "application/json",
                    },
                }
            )
                .then((res) => res.json())
                .then((response) => {
                    //  console.log(response);
                    if (response.IsSuccessful) {
                        //	sHelpers.secureStorage.setItem("inv.store.items", response.Items);
                        //this.setState({ storeItems: response.Items });

                        const mappedSuggestions = response.Items.map((item) => ({
                            ItemName: item.ItemName,
                            ItemCode: item.ItemCode,
                            UOM: item.Units,
                            Rate: item.ActualRate,
                            PickRate: null,
                            IngredientCode: null,
                        }));
                        this.setState({
                            items: mappedSuggestions,
                            filteredSuggestions: [],
                            itemInputValue: "",
                            selectedItemCode: 0,
                            selectedItemValue: "",
                        });
                        //this.itemSearchRef.current.focus();
                        //this.itemSearchRef.current.value = "";
                    } else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.Message);
                        sHelpers.secureStorage.setItem("inv.store.items", []);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // this.setState({ loading: false });
                    this.props.alert.error(err.message);
                    return;
                });
        } catch (error) {
            console.error("Fetch error:", error);
            // You can set an error state here or handle it as needed
            throw error;
        }
    };

    handleChangeProperty = async (event) => {
        const selectedPropertyCode = event.target.value;

        // Get stores and properties filtered based on the selected property code
        const _filterStore = sHelpers.secureStorage
            .getItem("inv.user.stores")
            .filter((s) => s.PropertyCode === Number(selectedPropertyCode));

        const _filterProperty = sHelpers.secureStorage
            .getItem("inv.user.properties")
            .filter((p) => p.PropertyCode === Number(selectedPropertyCode));

        // Set the selected property details
        this.setState({
            loading: true,
            storeList: _filterStore,
            selectedTypeId: "1",
            showStoreList: true,
            interDepartmentTransferDate:
                _filterProperty.length > 0 ? _filterProperty[0].RunDate : "",
            selectedPropertyCode: selectedPropertyCode,
            selectedPropertyName:
                _filterProperty.length > 0 ? _filterProperty[0].PropertyName : "",
        });

        // If there are stores available, set the first one as selected
        if (_filterStore.length > 0) {
            this.setState({
                selectedStoreCode: _filterStore[0].StoreCode,
                selectedStoreName: _filterStore[0].StoreName,
            });
            this.getStoreItems(selectedPropertyCode, _filterStore[0].StoreCode);
        }

        // Call the getDepartmentList function to fetch interdepartmenttransfer data
        await this.getDepartmentList(selectedPropertyCode);

        // After fetching interdepartmenttransfer, check the departmentList in state
        const { departmentList } = this.state;
        //console.log("departmentList", departmentList);
        // If there's only one interdepartmenttransfer available, set it as the selected interdepartmenttransfer code
        const _selectedFromDepartmentCode = this.state.selectedFromDepartmentCode;

        if (_selectedFromDepartmentCode === 0 || _selectedFromDepartmentCode === "") {
            if (departmentList.length !== 0) {
                // Set the first department as selected if none is selected
                this.setState({
                    selectedFromDepartmentCode: departmentList[0].DepartmentCode,
                    selectedFromDepartmentName: departmentList[0].DepartmentName
                });
            } else {
                // Reset the selected interdepartmenttransfer code if no departments are available
                this.setState({ selectedFromDepartmentCode: "", selectedFromDepartmentName: "" });
            }
            // When _selectedFromDepartmentCode is not 0, filter departmentList
            const filteredToDepartments = departmentList.filter(
                (department) => department.DepartmentCode !== Number(this.state.selectedFromDepartmentCode)
            );

            // If you want to set the first filtered department as selected, you can do so
            if (filteredToDepartments.length > 0) {
                this.setState({
                    selectedToDepartmentCode: filteredToDepartments[0].DepartmentCode,
                    selectedToDepartmentName: filteredToDepartments[0].DepartmentName
                });
            } else {
                // Handle the case when no filtered departments are available
                this.setState({ selectedToDepartmentCodeDepartmentCode: "", selectedToDepartmentName: "" });
            }
        }

        // Filter the department list to exclude the selected "From Department"
        const filteredToDepartments = this.state.departmentList.filter(
            (d) => d.DepartmentCode !== Number(this.state.selectedFromDepartmentCode)
        );

        this.setState({
            toDepartmentList: filteredToDepartments,  // Update the filtered "To Department" list
        });

        // Clear any previous data
        //this.clearData();
        // Only clear selectedItems if it's not edit mode
        if (this.state.mode !== "Edit") {
            this.setState({
                selectedItems: [], // Only clear items when not in edit mode
            });
            this.clearData();
        }
        else if (this.state.mode !== "Edit") {
            let fields = this.state.fields;
            this.setState({
                itemInputValue: "",
                selectedItemValue: "",
                filteredSuggestions: [],
                selectedItemCode: 0,
                selectedItemUnits: "",
                selectedItemRate: 0,
                selectedRecipeCode: null
            });
            this.itemSearchRef.current.value = "";
            this.itemSearchRef.current.focus();
            fields["interDepartmenttransferquantity"] = "";
            this.setState({ fields });
        }
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };

    handleReload() {
        window.location.reload(true);
    };

    handleAddItemClick() {
        // let remarks = this.state.fields["remarks"];
        // let enteredby = this.state.fields["enteredby"];
        let getSelectItems = this.state.selectedItems;
        let findItem = [];

        if (parseInt(this.state.selectedTypeId) === 1) {
            findItem = getSelectItems.filter(
                (itm) =>
                    itm.ItemName === this.state.selectedItemValue.toString() && // Corrected capitalization: 'ItemCode'
                    itm.ItemCode === this.state.selectedItemCode.toString() && // Corrected capitalization: 'ItemCode'
                    itm.StoreCode === parseInt(this.state.selectedStoreCode) &&
                    itm.TypeCode === parseInt(this.state.selectedTypeId) // Convert selectedTypeId to integer
            );
        }
        if (parseInt(this.state.selectedTypeId) !== 1) {
            findItem = getSelectItems.filter(
                (itm) =>
                    itm.ItemName === this.state.selectedItemValue.toString() && // Corrected capitalization: 'ItemCode'
                    itm.ItemCode === this.state.selectedItemCode.toString() &&
                    itm.TypeCode === parseInt(this.state.selectedTypeId)
            );
        }

        const trimmedItemValue = this.state.selectedItemValue.toString().trim();

        if (parseInt(this.state.selectedTypeId) === 1) {
            findItem = getSelectItems.filter(
                (itm) => itm.ItemName === trimmedItemValue
            );
        }
        if (parseInt(this.state.selectedTypeId) !== 1) {
            findItem = getSelectItems.filter(
                (itm) => itm.ItemName === trimmedItemValue
            );
        }

        if (findItem.length) {
            this.props.alert.error("Item already added.");
            document.getElementById("item-name").focus();
            return false;
        }

        const selectedItem = document.getElementById("item-name");
        const qty = this.state.fields["interDepartmenttransferquantity"]
            ? parseFloat(this.state.fields["interDepartmenttransferquantity"])
            : 0;
        let fields = this.state.fields;

        if (!sHelpers.validateNumeric(qty)) {
            this.props.alert.error("Please enter the Valid Qty");
            document.getElementById("interdepartmenttransfer-qty").focus();
            fields["interDepartmenttransferquantity"] = "";
            this.setState({ fields });
            this.handleScrollIntoView(this.qtyRef);
            return false;
        }
        if (!this.state.selectedItemValue) {
            this.setState({ itemInputValue: "" });
            this.props.alert.error("Please select the item");
            document.getElementById("item-name").focus();
            fields["interDepartmenttransferquantity"] = "";
            this.setState({ fields });
            this.handleScrollIntoView(this.itemSearchRef);
            return false;
        }

        if (qty <= 0) {
            this.props.alert.error("Please enter the valid qty");
            fields["interDepartmenttransferquantity"] = "";
            this.setState({ fields });
            window.scrollTo(0, 0);
            document.getElementById("interdepartmenttransfer-qty").focus();
            this.handleScrollIntoView(this.qtyRef);
            return false;
        }

        if (parseFloat(this.state.selectedItemRate) === 0) {
            this.props.alert.error("Rate must be greater than zero.");
            document.getElementById("item-name").focus();
            fields["interDepartmenttransferquantity"] = "";
            this.setState({ fields });
            this.handleScrollIntoView(this.itemSearchRef);
            return false;
        }
        let InterDepartmentTransferAmount =
            parseFloat(qty.toFixed(3)) * parseFloat(this.state.selectedItemRate);

        let obj = {
            StoreCode: this.state.selectedTypeId === "1" ? parseInt(this.state.selectedStoreCode) : 0,
            StoreName: this.state.selectedTypeId === "1" ? this.state.selectedStoreName : "",
            ItemName: selectedItem.value.trim(),
            ItemCode: this.state.selectedTypeId === "1" ? parseInt(this.state.selectedItemCode) : this.state.selectedItemCode,
            TypeCode: this.state.selectedTypeId,
            TypeName: this.state.selectedTypeName,
            Units: this.state.selectedItemUnits,
            InterDepartmentTransferRate: parseFloat(this.state.selectedItemRate).toFixed(2),
            InterDepartmentTransferQuantity: parseFloat(qty).toFixed(3),
            InterDepartmentTransferAmount: parseFloat(InterDepartmentTransferAmount).toFixed(2),
            RecipeItemCode: this.state.selectedRecipeCode
        };
        getSelectItems.push(obj);
        this.setState({
            loading: true,
            selectedItems: getSelectItems,
            itemInputValue: "",
            selectedItemValue: "",
            filteredSuggestions: [],
            selectedItemCode: 0,
            selectedItemUnits: "",
            selectedItemRate: 0,
            selectedRecipeCode: null

        });
        document.getElementById("item-name").focus();
        fields["interDepartmenttransferquantity"] = "";
        this.setState({ fields });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };

    handleDeleteItemCick(ItemCode, storeCode) {
        let getSelectItems = this.state.selectedItems;
        let index = getSelectItems.findIndex(
            (i) => i.ItemCode === ItemCode && i.StoreCode === storeCode
        );
        getSelectItems.splice(index, 1);
        this.setState({ selectedItems: getSelectItems, loading: true });
        this.props.alert.success("Item deleted successfully!");
        document.getElementById("item-name").focus();
        this.handleScrollIntoView(this.itemSearchRef);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };

    handleStoreChange(event) {
        const selectedStoreCode = event.target.value; // This is a string
        // Find the selected store by comparing store codes
        const selectedStore = this.state.storeList.find(
            (store) => store.StoreCode === parseInt(selectedStoreCode, 10)
        ); // Convert to number

        // Ensure that selectedStore exists to avoid potential errors
        if (selectedStore) {
            this.setState({
                loading: true,
                selectedStoreCode: selectedStoreCode, // StoreCode remains a string
                selectedStoreName: selectedStore.StoreName, // Update selectedStoreName state
            });
        }

        this.getStoreItems(this.state.selectedPropertyCode, event.target.value);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    };

    handleChangeFromDepartmentList(event) {
        const selectedFromDepartmentCode = event.target.value; // Get the selected department code
        // Find the department name based on the selected department code
        const selectedDepartment = this.state.departmentList.find(
            (d) => d.DepartmentCode === Number(selectedFromDepartmentCode)
        );

        // Filter the department list to exclude the selected "From Department"
        const filteredToDepartments = this.state.departmentList.filter(
            (d) => d.DepartmentCode !== Number(selectedFromDepartmentCode)
        );
        // Update state with the selected department code and department name
        this.setState({
            loading: true,
            selectedFromDepartmentCode: selectedFromDepartmentCode, // Update state with selected DepartmentCode
            selectedFromDepartmentName: selectedDepartment ? selectedDepartment.DepartmentName : "", // If found, set the name; otherwise, set an empty string
            toDepartmentList: filteredToDepartments,  // Update the filtered "To Department" list
        }, () => {
            // Log after the state is updated
            // console.log("Selected From Department:", this.state.selectedFromDepartmentName);
        });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };

    handleChangeToDepartmentList(event) {
        const selectedToDepartmentCode = event.target.value; // Get the selected department code
        // Find the department name based on the selected department code
        const selectedDepartment = this.state.departmentList.find(
            (d) => d.DepartmentCode === Number(selectedToDepartmentCode)
        );
        // Update state with the selected department code and department name
        this.setState({
            loading: true,
            selectedToDepartmentCode: selectedToDepartmentCode, // Update state with selected DepartmentCode
            selectedToDepartmentName: selectedDepartment ? selectedDepartment.DepartmentName : "", // If found, set the name; otherwise, set an empty string
        }, () => {
            // Log after the state is updated
            // console.log("Selected To Department:", this.state.selectedToDepartmentName);
        });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };


    handleTypeChange = (event) => {
        const selectedValue = event.target.value;
        const selectedType = this.state.typeLists.find(
            (type) => type.id === selectedValue
        );
        const selectedTypeName = selectedType ? selectedType.name : "";
        const showStoreList = selectedValue === "1";

        this.setState(
            {
                loading: true,
                selectedTypeId: selectedValue,
                selectedTypeName: selectedTypeName,
                showStoreList: showStoreList,
            },
            () => {
                // Fetch data based on the selected type after state update
                if (selectedValue === "2") {
                    this.getPosRecipeItems();
                } else if (selectedValue === "3") {
                    this.getIngredientItems();
                } else {
                    this.getStoreItems(
                        this.state.selectedPropertyCode,
                        this.state.selectedStoreCode
                    );
                }
            }
        );
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };

    handleSaveClick = async (e) => {
        try {
            e.preventDefault();

            if (this.state.selectedItems.length === 0) {
                this.props.alert.error("Please add at least one item");
                document.getElementById("item-name").focus();
                this.handleScrollIntoView(this.itemSearchRef);
                return false;
            }

            if (this.state.departmentList.length === 0) {
                this.props.alert.error("Please select the department");
                document.getElementById("from-department-list").focus();
                this.handleScrollIntoView(this.itemSearchRef);
                return false;
            }


            this.setState({ btnDisabled: true, loading: true });

            // console.log(this.state.selectedFromDepartmentCode, 'selectedFromDepartmentCode');
            // console.log(this.state.fields["enteredby"] || "", 'enterby');
            // console.log(this.state.fields["remarks"] || "", 'remarks');

            let postObj = {
                propertycode: parseInt(this.state.selectedPropertyCode), // Ensure it's an integer
                fromdepartmentcode: parseInt(this.state.selectedFromDepartmentCode), // Ensure it's an integer
                fromdepartmentname: this.state.selectedFromDepartmentName,
                todepartmentcode: parseInt(this.state.selectedToDepartmentCode), // Ensure it's an integer
                todepartmentname: this.state.selectedToDepartmentName,
                enterby: this.state.fields["enteredby"] || "",
                remarks: this.state.fields["remarks"] || "",
                login: sHelpers.secureStorage.getItem("inv.user.name"), // User login from secure storage
                SelectedInterDepartmentTransferItems: this.state.selectedItems, // Pass the array of items
                requestip: "0.0.0.1", // Static IP
            };

            console.log("postObj:", JSON.stringify(postObj));

            const encodedCredentials = btoa(
                `${Config.invAPIUserName}:${Config.invAPIPassword}`
            );

            const _apimethod = this.state.interDepartmentTransferDisplayNo === 0 ? "POST" : "PUT";
            const _loginUser = sHelpers.secureStorage.getItem("inv.user.name");

            // Construct the dynamic URL
            const url =
                _apimethod === "POST"
                    ? `${Config.baseAPIUrl}store/interdepartmenttransfers` // For POST (new entry)
                    : `${Config.baseAPIUrl}store/interdepartmenttransfers/${this.props.location.state.interDepartmentTransferNo}/${_loginUser}`; // For PUT (update with parameters)

            this.setState({ btnDisabled: true }); // Disable button while the request is being processed

            // Make the API request
            await fetch(url, {
                method: _apimethod,
                headers: {
                    Authorization: "Basic " + encodedCredentials,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postObj),
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response.IsSuccessful) {
                        this.props.alert.success(
                            `${response.Message} Inter Department Transfer No: ${response.InterDepartmentTransferNo}`
                        );
                        setTimeout(() => {
                            //window.location.reload(true); // Reload the page
                            this.setState({ selectedItems: [], btnDisabled: false, loading: false });
                        }, Config.averageMsgTimeout);
                    } else {
                        this.props.alert.error(response.Message || response[0]);
                        this.setState({ btnDisabled: false, loading: false });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ btnDisabled: false, loading: false });
                    this.props.alert.error(err.message);
                });
        } catch (error) {
            console.error("Fetch error:", error);
            throw error;
        }
    };

    handleItemSelect = (
        value,
        ItemCode,
        units,
        rate,
        pickRate,
        RecipeItemcode,
        ingredientcode
    ) => {
        //console.log("pickRate", pickRate);
        if (pickRate === 1) {
            if (this.state.selectedTypeId === "2") {
                this.getPosRecipeItemsRate(RecipeItemcode);
                this.setState({ selectedRecipeCode: RecipeItemcode });
            } else {
                this.setState({ selectedItemRate: rate });
            }
        } else if (this.state.selectedTypeId === "3") {
            this.getIngredientItemRate(ingredientcode);
        } else {
            this.setState({ selectedItemRate: rate });
        }

        this.setState({
            loading: true,
            filteredSuggestions: [],
            itemInputValue: value,
            selectedItemValue: value,
            selectedItemCode: ItemCode,
            selectedItemUnits: units,
        });

        let fields = this.state.fields;
        fields["interDepartmenttransferquantity"] = "";
        this.setState({ fields });
        document.getElementById("interdepartmenttransfer-qty").focus();
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };

    handleItemChange = (event) => {
        const inputValue = event.target.value;

        // Filter suggestions based on input value
        const filteredSuggestions = this.state.items.filter((suggestion) =>
            suggestion.ItemName.toLowerCase().includes(inputValue.toLowerCase())
        );

        this.setState({
            itemInputValue: inputValue,
            filteredSuggestions: filteredSuggestions,
        });
        this.setState({
            loading: true,
            selectedItemValue: "",
            selectedItemCode: 0,
            selectedItemUnits: "",
            selectedItemRate: 0,
            selectedRecipeCode: null
        });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    };

    handleItemFocus = (event) => {
        event.target.select();
    };

    handleMode() {
        if (entryMode.view === this.state.mode) {
            this.setState({
                btnVisible: false, ctrlDisabled: true, selectionStoreVisible: false,
                selectionItemVisible: false
            });
            this.handleScrollIntoView(this.headerRef);
        }
        if (entryMode.delete === this.state.mode) {
            this.setState({
                btnDeleteVisabled: true,
                btnVisible: false,
                ctrlDisabled: true,
                selectionStoreVisible: false,
                selectionItemVisible: false
            });
            this.handleScrollIntoView(this.headerRef);
        } else this.clearData();
    };

    clearData() {
        let fields = this.state.fields;
        this.setState({
            selectedItems: [],
            itemInputValue: "",
            selectedItemValue: "",
            filteredSuggestions: [],
            selectedItemCode: 0,
            selectedItemUnits: "",
            selectedItemRate: 0,
            selectedRecipeCode: null
        });
        if (
            !(
                this.props.location.state.mode === "View" ||
                this.props.location.state.mode === "Delete"
            )
        ) {
            this.itemSearchRef.current.value = "";
            this.itemSearchRef.current.focus();
        }

        fields["interDepartmenttransferquantity"] = "";
        this.setState({ fields });
    };

    goBack() {
        this.props.history.push("/interdepartmenttransfer");
    };

    handleScrollIntoView(scrollRef) {
        window.scrollTo(0, 0);
        setTimeout(() => {
            scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }, Config.averageMsgTimeout);
    };

    getViewInterDepartmentTransferList = async (interdepartmenttransferno) => {
        try {
            const encodedCredentials = btoa(
                `${Config.invAPIUserName}:${Config.invAPIPassword}`
            );

            await fetch(
                Config.baseAPIUrl + "store/interdepartmenttransfers/" + interdepartmenttransferno,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Basic " + encodedCredentials,
                        "Content-type": "application/json",
                    },
                }
            )
                .then((res) => res.json())
                .then((response) => {
                    if (response.IsSuccessful) {
                        this.setState({ selectedItems: response.Detail });
                        this.state.isEditMode = this.state.mode === entryMode.edit;
                        this.getDepartmentList(response.Header.PropertyCode);

                        setTimeout(() => {
                            this.setState({
                                selectedPropertyCode: response.Header.PropertyCode,
                                selectedPropertyName: response.Header.PropertyName,
                                selectedFromDepartmentCode: response.Header.FromDepartmentCode,
                                selectedFromDepartmentName: response.Header.FromDepartmentName,
                                selectedToDepartmentCode: response.Header.ToDepartmentCode,
                                selectedToDepartmentName: response.Header.ToDepartmentName,
                            });

                        }, Config.minMsgTimeout);

                        this.setState({
                            interDepartmentTransferDate: response.Header.InterDepartmentTransferDate + " " + response.Header.InterDepartmentTransferTime,
                            interDepartmentTransferDisplayNo: response.Header.DisplayInterDepartmentTransferNo,
                            fields: { ...this.state.fields, remarks: response.Header.Remarks, enteredby: response.Header.EnterBy },
                        });

                        setTimeout(() => {
                            // After setting state, call `handleChangeProperty` if in edit mode
                            if (this.state.isEditMode) {
                                // Trigger handleChangeProperty with the newly set selectedPropertyCode
                                this.handleChangeProperty({ target: { value: this.state.selectedPropertyCode } });
                            }
                        }, Config.minMsgTimeout);

                    } else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.alert.error(err.message);
                    return;
                });
        } catch (error) {
            console.error("Fetch error:", error);
            throw error;
        }
    };

    handleDeleteClick = async () => {
        try {
            const encodedCredentials = btoa(
                `${Config.invAPIUserName}:${Config.invAPIPassword}`
            );
            this.setState({ loading: true });
            await fetch(
                Config.baseAPIUrl +
                "store/interdepartmenttransfers/" +
                this.props.location.state.interDepartmentTransferNo +
                "/" +
                sHelpers.secureStorage.getItem("inv.user.name"),
                {
                    method: "DELETE",
                    headers: {
                        Authorization: "Basic " + encodedCredentials,
                        "Content-type": "application/json",
                    },
                }
            )
                .then((res) => res.json())
                .then((response) => {
                    //console.log("DeleteInterDepartmentTransferList", response.Message);
                    if (response.IsSuccessful) {
                        this.props.alert.success(response.Message);
                        setTimeout(() => {
                            this.goBack();
                        }, 2000);
                    } else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.Message);
                        setTimeout(() => {
                            this.goBack();
                        }, 2000);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.alert.error(err.message);
                    return;
                });
        } catch (error) {
            console.error("Fetch error:", error);
            throw error;
        }
    };

    getDepartmentList = async (propertyCode) => {
        // Get departments from secure storage
        const departments = sHelpers.secureStorage.getItem("inv.user.departments");

        // Filter departments based on the selected property code
        // Filter departments based on the selected property code and ensure selectedFromDepartmentCode is excluded
        const filteredDepartments = departments.filter(
            (dep) =>
                dep.PropertyCode === Number(propertyCode)  // Correct property code
        );

        // Further filter out the department with the selectedFromDepartmentCode
        const toDepartmentList = filteredDepartments.filter(
            (dep) => dep.DepartmentCode !== Number(this.state.selectedFromDepartmentCode)
        );

        // Update state with both the full department list and the filtered toDepartmentList
        this.setState({
            departmentList: filteredDepartments,  // This is the full department list
            toDepartmentList: toDepartmentList    // This is the list excluding From Department
        });
    };

    //POS Recipe Items
    getPosRecipeItems = async () => {
        try {
            let RequestIP = "127.0.0.0";
            var _orgid = sHelpers.secureStorage.getItem("inv.organization.id");
            //console.log('_orgid',_orgid);

            const encodedCredentials = btoa(
                `${Config.invAPIUserName}:${Config.invAPIPassword}`
            );

            await fetch(
                Config.baseAPIUrl +
                "common/posrecipeitems?organizationId=" +
                _orgid +
                "&requestip=" +
                RequestIP,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Basic " + encodedCredentials,
                        "Content-type": "application/json",
                    },
                }
            )
                .then((res) => res.json())
                .then((response) => {
                    //	console.log("POSRecipeItems", response);
                    if (response.IsSuccessful) {
                        //this.setState({ filteredSuggestions: response.POSRecipeItems });

                        this.setState({
                            items: response.POSRecipeItems,
                            filteredSuggestions: [],
                            itemInputValue: "",
                            selectedItemCode: 0,
                            selectedItemValue: "",
                        });

                        //this.itemSearchRef.current.value = "";
                        //this.itemSearchRef.current.focus();
                    } else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.alert.error(err.message);
                    return;
                });
        } catch (error) {
            console.error("Fetch error:", error);
            throw error;
        }
    };

    //POS Recipe Items Rate
    getPosRecipeItemsRate = async (recipecode) => {
        try {
            let RequestIP = "127.0.0.0";
            var _orgid = sHelpers.secureStorage.getItem("inv.organization.id");
            //console.log("_orgid", _orgid);

            const encodedCredentials = btoa(
                `${Config.invAPIUserName}:${Config.invAPIPassword}`
            );

            await fetch(
                Config.baseAPIUrl +
                "common/posrecipeitemrate?organizationId=" +
                _orgid +
                "&requestip=" +
                RequestIP +
                "&recipecode=" +
                recipecode,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Basic " + encodedCredentials,
                        "Content-type": "application/json",
                    },
                }
            )
                .then((res) => res.json())
                .then((response) => {
                    //console.log("posrecipeitemrate", response);
                    if (response.IsSuccessful) {
                        this.setState({ selectedItemRate: response.Rate });
                    } else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.alert.error(err.message);
                    return;
                });
        } catch (error) {
            console.error("Fetch error:", error);
            throw error;
        }
    };

    //Ingredient Item
    getIngredientItems = async () => {
        try {
            let RequestIP = "127.0.0.0";
            var _orgid = sHelpers.secureStorage.getItem("inv.organization.id");
            const encodedCredentials = btoa(
                `${Config.invAPIUserName}:${Config.invAPIPassword}`
            );

            await fetch(
                Config.baseAPIUrl +
                "common/ingredientitems?organizationId=" +
                _orgid +
                "&requestip=" +
                RequestIP,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Basic " + encodedCredentials,
                        "Content-type": "application/json",
                    },
                }
            )
                .then((res) => res.json())
                .then((response) => {
                    //console.log("IngredientItems", response);
                    if (response.IsSuccessful) {
                        const mappedSuggestions = response.IngredientItems.map((item) => ({
                            ItemName: item.IngredientName, // Mapping IngredientName to ItemName
                            ItemCode: item.IngredientCode, // Mapping IngredientCode to ItemCode
                            UOM: item.UOM, // UOM stays the same
                            Rate: item.Rate, // Rate stays the same
                            PickRate: null,
                            IngredientCode: item.IngredientCode, // Default value for PickRate if required
                        }));

                        this.setState({
                            items: mappedSuggestions,
                            filteredSuggestions: [],
                            itemInputValue: "",
                            selectedItemCode: 0,
                            selectedItemValue: "",
                        });
                        //this.itemSearchRef.current.value = "";
                        //this.itemSearchRef.current.focus();
                        //this.setState({ filteredSuggestions: mappedSuggestions });
                    } else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.alert.error(err.message);
                    return;
                });
        } catch (error) {
            console.error("Fetch error:", error);
            throw error;
        }
    };

    //Ingredient Item Rate
    getIngredientItemRate = async (ingredientcode) => {
        try {
            let RequestIP = "127.0.0.0";
            var _orgid = sHelpers.secureStorage.getItem("inv.organization.id");
            const encodedCredentials = btoa(
                `${Config.invAPIUserName}:${Config.invAPIPassword}`
            );

            await fetch(
                Config.baseAPIUrl +
                "common/ingredientitemrate?organizationId=" +
                _orgid +
                "&requestip=" +
                RequestIP +
                "&ingredientcode=" +
                ingredientcode,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Basic " + encodedCredentials,
                        "Content-type": "application/json",
                    },
                }
            )
                .then((res) => res.json())
                .then((response) => {
                    //	console.log("ingredientitemrate", response);
                    if (response.IsSuccessful) {
                        this.setState({ selectedItemRate: response.Rate });
                    } else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.Message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.props.alert.error(err.message);
                    return;
                });
        } catch (error) {
            console.error("Fetch error:", error);
            throw error;
        }
    };

    render() {
        const { paddingTop, paddingBottom, mode } = this.state;
        // Calculate total amount and total item count
        const totalAmount = this.state.selectedItems.reduce(
            (sum, item) => sum + parseFloat(item.InterDepartmentTransferAmount),
            0
        );
        const totalItems = this.state.selectedItems.length;
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <PageLoading />
                <main
                    className="h-100"
                    ref={this.mainRef}
                    style={{
                        paddingTop: `${paddingTop}px`,
                        paddingBottom: `${paddingBottom}px`,
                    }}
                >
                    <header ref={this.headerRef} className="header position-fixed">
                        <div className="row">
                            <div className="col-auto">
                                <button
                                    className="btn btn-light btn-44 back-btn"
                                    onClick={this.goBack.bind(this)}
                                >
                                    <i className="bi bi-arrow-left"></i>
                                </button>
                            </div>
                            <div className="col align-self-center text-center">
                                <h5>Inter Department Transfer</h5>
                            </div>
                        </div>
                    </header>

                    <div className="main-container container">
                        <div className="row mb-3">
                            <div className="col">
                                <h6 className="title">Transfer Entry</h6>
                            </div>
                            <div className="col-auto align-self-center">
                                <span className="tag bg-primary text-white border-0 py-1 px-2 float-end mt-1">
                                    {mode}
                                </span>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <button
                                    className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
                                    onClick={this.handleReload.bind(this)}
                                    disabled={this.state.btnDisabled}
                                    style={{
                                        display: this.state.isEditMode ? "none" : this.state.btnVisible ? "block" : "none", // Hide in edit mode
                                    }}                                >
                                    <i className="bi bi-reply-fill me-2"></i>Reset
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
                                    onClick={this.handleSaveClick.bind(this)}
                                    disabled={this.state.btnDisabled}
                                    style={{ display: this.state.btnVisible ? "block" : "none" }}
                                >
                                    <i className="bi bi-check-lg me-2"></i> Save
                                </button>
                            </div>
                            {this.state.btnDeleteVisabled && (
                                <div className="col">
                                    <button
                                        className="btn btn-danger shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
                                        onClick={this.handleDeleteClick.bind(this)}
                                    >
                                        <i className="bi bi-trash me-2"></i> Delete
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="row mb-4">
                            <div className="col-auto">
                                <div className="avatar avatar-44 rounded-10 shadow-sm">
                                    <i className="bi bi-calendar-range size-22"></i>
                                </div>
                            </div>

                            <div className="col align-self-center ps-0">
                                <h6>{this.state.interDepartmentTransferDate}</h6>
                                <p className="mb-1 text-muted">Date</p>
                            </div>

                            {this.state.interDepartmentTransferDisplayNo > 0 && (
                                <div className="col align-self-center text-center text-end">
                                    <h6>{this.state.interDepartmentTransferDisplayNo}</h6>
                                    <p className="mb-1 text-muted">Transfer No</p>
                                </div>
                            )}
                        </div>
                        <div className="row h-100">
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="form-floating mb-3 ">
                                    <select
                                        className="form-control"
                                        id="interdepartmenttransfer-properties"
                                        onChange={this.handleChangeProperty.bind(this)}
                                        value={this.state.selectedPropertyCode}
                                        // disabled={this.state.ctrlDisabled}
                                        disabled={this.state.isEditMode ? true : this.state.ctrlDisabled} // Disable only if not edit mode
                                    >
                                        {this.state.propertyList.map((prop) => (
                                            <option value={prop.PropertyCode}>
                                                {prop.PropertyName}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="country">Property</label>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-control"
                                        id="from-department-list"
                                        onChange={this.handleChangeFromDepartmentList}
                                        value={this.state.selectedFromDepartmentCode}
                                        disabled={
                                            this.state.ctrlDisabled ||
                                            this.state.departmentList.length === 0
                                        }
                                    >
                                        {this.state.departmentList &&
                                            this.state.departmentList.length > 0 &&
                                            this.state.departmentList.map((department) => (
                                                <option
                                                    value={department.DepartmentCode} // Use DepartmentCode for the value
                                                    key={department.DepartmentCode}
                                                >
                                                    {department.DepartmentName} {/* Display DepartmemtName */}
                                                </option>
                                            ))}
                                    </select>
                                    <label htmlFor="from-department-list">From Department</label>
                                </div>
                            </div>


                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-control"
                                        id="to-department-list"
                                        onChange={this.handleChangeToDepartmentList.bind(this)} // Bind the handler
                                        value={this.state.selectedToDepartmentCode}
                                        disabled={
                                            this.state.ctrlDisabled ||
                                            this.state.toDepartmentList.length === 0  // Check if toDepartmentList has items
                                        }
                                    >
                                        {this.state.toDepartmentList &&
                                            this.state.toDepartmentList.length > 0 &&
                                            this.state.toDepartmentList.map((department) => (
                                                <option
                                                    value={department.DepartmentCode} // Use DepartmentCode for the value
                                                    key={department.DepartmentCode}
                                                >
                                                    {department.DepartmentName} {/* Display DepartmentName */}
                                                </option>
                                            ))}
                                    </select>
                                    <label htmlFor="to-department-list">To Department</label>
                                </div>
                            </div>



                            {!(
                                this.props.location.state.mode === "View" ||
                                this.props.location.state.mode === "Delete"
                            ) && (
                                    <>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="form-floating mb-3">
                                                <select
                                                    className="form-control"
                                                    id="type-list"
                                                    onChange={this.handleTypeChange} // Handle selection change
                                                    disabled={this.state.ctrlDisabled}
                                                    value={this.state.selectedTypeId}
                                                >
                                                    {this.state.typeLists.map((type) => (
                                                        <option value={type.id} key={type.id}>
                                                            {type.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="type-list">Type</label>
                                            </div>
                                        </div>

                                        {this.state.showStoreList && ( // Conditionally render this block if showStoreList is true
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="form-floating mb-3 ">
                                                    <select
                                                        className="form-control"
                                                        id="variation-stores"
                                                        onChange={this.handleStoreChange.bind(this)}
                                                        disabled={this.state.ctrlDisabled}
                                                    >
                                                        {this.state.storeList.map((store) => (
                                                            <option
                                                                value={store.StoreCode}
                                                                key={store.StoreCode}
                                                            >
                                                                {store.StoreName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="variation-stores">Store</label>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                        </div>

                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="form-group form-floating mb-3">
                                <input
                                    ref={this.enteredByRef}
                                    type="text"
                                    className="form-control"
                                    placeholder="Entered By"
                                    id="interdepartmenttransfer-entered-by"
                                    onChange={this.handleChange.bind(this, "enteredby")}
                                    value={this.state.fields["enteredby"]}
                                    maxLength="30"
                                    disabled={this.state.ctrlDisabled}
                                />
                                <label htmlFor="interdepartmenttransfer-entered-by">Entered By</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="form-group form-floating  mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Remarsk"
                                    id="interdepartmenttransfer-remarks"
                                    onChange={this.handleChange.bind(this, "remarks")}
                                    value={this.state.fields["remarks"]}
                                    maxLength="30"
                                    disabled={this.state.ctrlDisabled}
                                />
                                <label htmlFor="interdepartmenttransfer-remarks">Remarks</label>
                            </div>
                        </div>

                        <div className="row">
                            {!(
                                this.props.location.state.mode === "View" ||
                                this.props.location.state.mode === "Delete"
                            ) && (
                                    <>
                                        <div className="col-7">
                                            <div className="form-group form-floating mb-2 shadow-sm">
                                                <div className="form-group form-floating">
                                                    <input
                                                        ref={this.itemSearchRef}
                                                        className="autocomplete-input form-control"
                                                        type="text"
                                                        value={this.state.itemInputValue || ""}
                                                        onChange={this.handleItemChange}
                                                        onFocus={this.handleItemFocus}
                                                        id="item-name"
                                                        autoComplete="off"
                                                        disabled={this.state.ctrlDisabled}
                                                    />

                                                    <label htmlFor="item-name">Item Search</label>
                                                </div>

                                                <ul className="autocomplete-suggestions">
                                                    {this.state.filteredSuggestions.map(
                                                        (suggestion, index) => (
                                                            <li
                                                                key={index}
                                                                className="autocomplete-suggestion"
                                                                onClick={() =>
                                                                    this.handleItemSelect(
                                                                        suggestion.ItemName,
                                                                        suggestion.ItemCode,
                                                                        suggestion.UOM,
                                                                        suggestion.Rate,
                                                                        suggestion.PickRate,
                                                                        suggestion.RecipeItemcode,
                                                                        suggestion.IngredientCode
                                                                    )
                                                                }
                                                            >
                                                                {suggestion.ItemName}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-5">
                                            <input
                                                ref={this.qtyRef}
                                                type="text"
                                                className="trasparent-input text-end variation-entry-qty"
                                                id="interdepartmenttransfer-qty"
                                                placeholder="0.00"
                                                onChange={this.handleChange.bind(this, "interDepartmenttransferquantity")}
                                                value={this.state.fields["interDepartmenttransferquantity"]}
                                                maxLength="10"
                                                autoComplete="off"
                                                disabled={this.state.ctrlDisabled}
                                                inputmode="numeric"
                                            />
                                        </div>

                                        <div className="col-12 mb-2">
                                            <span className="text-muted size-12 me-2">
                                                Units:{" "}
                                                <span className="text-color-theme size-12 small">
                                                    {this.state.selectedItemUnits}
                                                </span>{" "}
                                            </span>
                                            <span className="text-muted size-12 me-2">
                                                Rate:{" "}
                                                <span className="text-color-theme size-12 small">
                                                    {this.state.selectedItemRate}
                                                </span>{" "}
                                            </span>
                                            {/* <span className="text-muted size-12">
											Amount:{" "}
											<span className="text-color-theme size-12 small">
												{this.state.selectedItemRate}
											</span>{" "}
										</span> */}
                                        </div>
                                    </>
                                )}

                            <div className="col-12">
                                {/* <button className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100" onClick={this.handleAddItemPopup.bind(this)}> */}
                                <button
                                    className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
                                    onClick={this.handleAddItemClick.bind(this)}
                                    disabled={this.state.btnDisabled}
                                    style={{ display: this.state.btnVisible ? "block" : "none" }}
                                >
                                    <i className="bi bi-plus-lg me-2"></i> Add Item
                                </button>

                                {/* Display Total Amount and Total Items */}
                                <div className="row my-3">
                                    {totalAmount > 0 && (
                                        <div className="col-6 text-start">
                                            <span className="text-muted size-12 me-2">
                                                Total Amount: <span className="text-color-theme size-12 small">{totalAmount.toFixed(2)}</span>
                                            </span>
                                        </div>
                                    )}
                                    {totalItems > 0 && (
                                        <div className="col-6 text-end">
                                            <span className="text-muted size-12 me-2">
                                                Total Items: <span className="text-color-theme size-12 small">{totalItems}</span>
                                            </span>
                                        </div>
                                    )}
                                </div>

                            </div>


                        </div>
                        <div className="row mb-4">
                            <div className="card shadow-sm mb-4">
                                <div className="col-12 px-0">
                                    <ul className="list-group list-group-flush bg-none">
                                        {this.state.selectedItems.map((item, id) => (
                                            <li className="list-group-item" id={"itm-sl-" + id}>
                                                <div className="row">
                                                    <div className="col-4 align-self-center text-left">
                                                        <p className="text-color-theme mb-0">
                                                            {item.ItemName}
                                                        </p>
                                                        <p className="size-12">{item.TypeName}</p>
                                                        <p className="text-muted size-12">
                                                            {item.StoreName}
                                                        </p>
                                                    </div>
                                                    <div className="col-4 align-self-center text-left">
                                                        <p className="mb-0">{item.units}</p>
                                                        <p className="text-muted size-12">
                                                            Rate: {item.InterDepartmentTransferRate}
                                                        </p>
                                                    </div>
                                                    <div className="col-4 align-self-center text-left">
                                                        <p className="text-muted size-12">
                                                            Qty: {item.InterDepartmentTransferQuantity}
                                                        </p>
                                                        <p className="text-muted size-12">
                                                            Amount: {item.InterDepartmentTransferAmount}
                                                        </p>
                                                    </div>

                                                    <div className="col-auto align-self-center">
                                                        <button
                                                            className="btn btn-light text-danger shadow-sm"
                                                            onClick={this.handleDeleteItemCick.bind(
                                                                this,
                                                                item.ItemCode,
                                                                item.StoreCode
                                                            )}
                                                            style={{
                                                                display: this.state.btnVisible
                                                                    ? "block"
                                                                    : "none",
                                                            }}
                                                        >
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}
export default withAlert()(interdepartmenttransferentry);
