import React, { Component, createRef } from "react";

export default class AddItemRatePopup extends Component {
    constructor(props) {
        super(props)
        this.qtyRef = createRef();
        this.handleAddItemRatePopup = this.handleAddItemRatePopup.bind(this);
        this.state = {       
                isEditingRate: false,
                newRate: "",
                ctrlDisabled: false,
              errorMessage: '',
        };

    }
    
    handleAddItemRatePopup() {
        // Open the modal and set focus on the input field
        const modalElement = document.getElementById("attachefiles");
        if (modalElement) {
          modalElement.classList.add("show");
          modalElement.setAttribute("aria-hidden", "false");
          modalElement.style.display = "block";
          document.body.classList.add("modal-open");
    
          // Set focus to the rate input field when modal opens
          setTimeout(() => {
            if (this.qtyRef.current) {
              this.qtyRef.current.focus();
            }
          }, 200);
        }
        this.setState({
          isEditingRate: true,
        });
      }
 
    // handleRateChange = (event) => {
    //     const rate = event.target.value;
    //     if (/^\d*(\.\d*)?$/.test(rate)) {
    //       this.setState({ newRate: rate, errorMessage: "" });
    //     } else {
    //       this.setState({ errorMessage: "Please enter a valid rate (numbers only)." });
    //     }
    //   };
    handleRateChange = (event) => {
      const rate = event.target.value;
  
      // Check if input contains only valid numbers
      if (/^[\d.]*$/.test(rate)) {
          // Check if input exceeds 3 decimal places
          if (/^\d*(\.\d{0,3})?$/.test(rate)) {
              this.setState({ newRate: rate, errorMessage: "" });
          } else {
              this.setState({ errorMessage: "Please enter a valid rate with up to 3 decimal places." });
          }
      } else {
          this.setState({ errorMessage: "Please enter a valid rate (numbers only)." });
      }
  };
  
  
    

      handleRateSave = () => {
        const { newRate } = this.state;
        if (newRate === "" || isNaN(parseFloat(newRate))) {
          this.setState({ errorMessage: "Please enter a valid rate." });
          return;
        }
    
        this.props.onRateUpdate(parseFloat(newRate));
    
        const modalElement = document.getElementById("attachefiles");
        if (modalElement) {
          modalElement.classList.remove("show");
          modalElement.setAttribute("aria-hidden", "true");
          modalElement.style.display = "none";
          document.body.classList.remove("modal-open");
    
          // Remove backdrop if it exists
          const backdrop = document.querySelector(".modal-backdrop");
          if (backdrop) {
            backdrop.remove();
          }
        }
    
        // Reset the form state
        this.setState({ isEditingRate: false,  newRate: "", errorMessage: "", });
        document.getElementById("kitchen-qty").focus();
      };

      handleCancelPopup = () => {
        // Manually hide the modal
        const modalElement = document.getElementById("attachefiles");
        if (modalElement) {
          modalElement.classList.remove("show");
          modalElement.setAttribute("aria-hidden", "true");
          modalElement.style.display = "none";
          document.body.classList.remove("modal-open");
      
          // Remove backdrop if it exists
          const backdrop = document.querySelector(".modal-backdrop");
          if (backdrop) {
            backdrop.remove();
          }
        }
        // Optionally reset any other states if needed
        this.setState({ isEditingRate: false, newRate: "",  errorMessage: "", });
        document.getElementById("kitchen-qty").focus();
      };
      

    render() {
        return (
            <>
                <div className="section-image">
                    <div className="menu-goup">
                        <button className="theme-button btn" style={{ display: "none" }} ref={input => this.inputElement = input} data-toggle="modal" data-target="#attachefiles"><i className="fa fa-utensils sidebar-icon"></i> add</button>
                    </div>
                    <div className="modal fade" id="image-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content animate-bottom">
                                <div className="modal-header p-2">                                

                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" id="div-request-body" style={{ marginTop: "-12px" }}>
                                    <div className='single-item-wrapper'>
                                        <div className='single-item'>
                                            <div className='row'>
                                                <img className='img-fluid item-img' src="{this.props.imgUrl}" loading="lazy" alt="{this.props.imgItemName + '-lucid-pos'}" style={{ objectFit: "cover", width: "100%" }} />
                                            </div>
                                        </div>
                                        <div className='container-fluid single-item mt-3 pb-2'>
                                            <div className="row mb-2 mt-2 align-items-start">
                                                <app-item-type>
                                                    <div className="single-image-type">
                                                        {this.props.imgItemFType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {this.props.imgItemFType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    <div className='item-name'>item name</div>
                                                </div>
                                                <div className='col text-right'>
                                                    <p className='item-price '>
                                                        <span>₹120</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col">
                                                    <p className="item-description">asdsf sdfs fsdf safsaf</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal fade " id="attachefiles" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog modal-xsm modal-dialog-centered ">
                            <div class="modal-content shadow border-0">
                                <div class="modal-body">
                                    
                                    {this.state.isEditingRate && (
                                     <div className="row">
                                         <div className="col-12">
                                         
                                        <div>
                                              <input 
                                                ref={this.qtyRef}
                                               type="text"  className="trasparent-input text-end variation-entry-qty" id='popup-item-rate' disabled={this.state.ctrlDisabled} placeholder="0.000"  value={this.state.newRate}  maxLength="10" autoComplete="off" inputmode="numeric" onChange={this.handleRateChange}  />
                                              {this.state.errorMessage && (
                                               <div className="text-danger">{this.state.errorMessage}</div>
                                          )}
                                           </div>
                                          </div>
                                          <div className="col-12 d-flex justify-content-center">
                                       <button className="btn btn-default shadow-sm avatar avatar-44 p-0 mt-3 me-2 text-white w-50 center" onClick={this.handleRateSave} > OK </button>
                                       <button className="btn btn-default shadow-sm avatar avatar-44 p-0 mt-3 me-2 text-white w-50 center" onClick={this.handleCancelPopup} > Cancel </button>
                                      </div>
                                     </div>
                                         )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>

        );
    }

}