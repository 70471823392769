import React, { Component, createRef } from "react";
import { withAlert } from "react-alert"; // HOC to show the alert message
import Config, { entryMode, printSetting } from "../../../data/siteconfig";
import sHelpers from "../../../modules/helpers/statichelpers";
import PageLoading from "../../../component/loadinganimation/pageloading";
import "./kitchenwastage.scss";
import "./autocomplete.scss";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
let _yValue = printSetting.logoy;
class kitchenwastageprint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			paddingTop: 0,
			paddingBottom: 0,
			selectedPropertyCode: 0,
			selectedPropertyName: "",
			selectedDepartmentCode: 0,
			selectedDepartmentName: "",
			kitchenWastageNo: 0,
			kitchenWastageDate: "-",
			kitchenWastageTime: "-",
			selectedItems: [],
			remarks: "-",
			enterBy: "-",
			login: "-",
			btnDisabled: false,
			btnVisible: false,
			selectedItemValue: "",
			gst: "-",
			footer: '',
			city: '',
			state: '',
			pin: '',
			mobile: '',
			phone: '',
			email: '',
			address1: '',
			address2: '',
			logoUrl: ''
		};
		this.mainRef = createRef();
		this.headerRef = createRef();
		this.footerRef = createRef();
		this.itemSearchRef = React.createRef();
		this.qtyRef = React.createRef();
		this.enterByRef = React.createRef();
	}

	updateStyles = () => {
		const windowHeight = window.innerHeight;
		const headerHeight = this.headerRef.current
			? this.headerRef.current.offsetHeight
			: 0;
		const footerHeight = this.footerRef.current
			? this.footerRef.current.offsetHeight
			: 0;

		if (this.mainRef.current) {
			this.mainRef.current.style.minHeight = `${windowHeight}px`;
			this.setState({
				paddingTop: headerHeight + 10,
				paddingBottom: footerHeight + 10,
			});
		}
	};

	componentDidMount() {
		this.updateStyles();
		window.addEventListener("resize", this.updateStyles);

		if (!sHelpers.LoggedinInputValidation()) {
			this.props.alert.error("Session Expired! Please log in again.");
			this.setState({ loading: true });
			setTimeout(() => {
				this.props.history.push("/login");
			}, Config.minMsgTimeout);
			return false;
		}

		if (this.props.location.state) {
			this.setState({ kitchenWastageNo: this.props.location.state.kitchenWastageNo });
			setTimeout(() => {
				if (this.props.location.state.mode === entryMode.print) {
					this.getKitchenWastagePrint(this.props.location.state.kitchenWastageNo);
				} else {
					this.props.history.push("/kitchenwastage");
				}
			}, Config.minMsgTimeout);

			window.scrollTo(0, 0);
		} else {
			setTimeout(() => {
				this.props.history.push("/kitchenwastage");
			}, Config.minMsgTimeout);
		}

		// handleMode
	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateStyles);
	};

	handleScrollIntoView(scrollRef) {
		window.scrollTo(0, 0);
		setTimeout(() => {
			scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}, Config.averageMsgTimeout);
	};

	getKitchenWastagePrint = async (kitchenWastageNo) => {
		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(Config.baseAPIUrl + "store/kitchenwastages/print/" + kitchenWastageNo, {
				method: "GET",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((response) => {
					//console.log("getkitchenwastage", response);
					if (response.IsSuccessful) {
						// Store Detail values in the state for rendering
						//	 this.setState({ kitchenWastageHeader: response.Header });
						this.setState({ selectedItems: response.Detail, btnVisible: true });
						this.setState({
							kitchenWastageDate: response.Header.KitchenWastageDate,
							kitchenWastageTime: response.Header.KitchenWastageTime,
							remarks: response.Header.Remarks,
							enterBy: response.Header.EnterBy,
							selectedPropertyName: response.Header.PropertyName,
							selectedPropertyCode: response.Header.PropertyCode,
							selectedDepartmentName: response.Header.DepartmentName,
							selectedDepartmentCode: response.Header.DepartmentCode,
							gst: response.Header.GST,
							selectedItems: response.Detail,
							login: response.Header.Login,
							state: response.Header.State,
							mobile: response.Header.Mobile,
							phone: response.Header.Phone,
							pin: response.Header.ZipCode,
							email: response.Header.Email,
							address1: response.Header.Address1,
							address2: response.Header.Address2,
							city: response.Header.City,
							logoUrl: response.Header.LogoUrl
						});
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	handleExportToPdf = () => {

		const lMargin = 15; // Left margin in mm
		const rMargin = 15; // Right margin in mm
		const pdfInMM = 210; // Width of A4 in mm
		let pdf = new jsPDF("p", "mm", "a4"); // Initialize jsPDF instance
		_yValue = printSetting.logoy;

		if (this.state.logoUrl) {
			const _imgData = "data:image/png;base64," + this.state.logoUrl + "";
			//pdf.addImage(_imgData, printSetting.logox, printSetting.logoy, printSetting.logowidth, printSetting.logoheight);
			pdf.addImage(_imgData, 'JPEG', printSetting.logox, printSetting.logoy, printSetting.logowidth, printSetting.logoheight);
		}

		// Set font and font size
		pdf.setFont("courier");
		pdf.setFontSize(16);

		_yValue += 20;

		// Custom function to center text
		const centerText = (text, y) => {
			let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
			let textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
			pdf.text(textOffset, y, text);
		};

		// Add header information (Property Name)
		const propertyName = this.state.selectedPropertyName || "Property Name";
		centerText(propertyName, _yValue);
		//_yValue = _yValue; // Initialize Y value for spacing after header

		// Reduce font size for further details
		pdf.setFontSize(12);

		// Update address and other details from state or default values
		let addr1 = String(this.state.address1 || "");
		let addr2 = String(this.state.address2 || "");
		let city = String(this.state.city || "");
		let state = String(this.state.state || "");
		let pin = String(this.state.pin || "");
		let mobile = String(this.state.mobile || "");
		let phone = String(this.state.phone || "");
		let email = String(this.state.email || "");
		let Login = sHelpers.secureStorage.getItem("inv.user.name") || "super"; // User Login


		// Add address information (Address Line 1 and Address Line 2)
		if (addr1.trim() !== "" || addr2.trim() !== "") {
			let fullAddress = addr1 + " " + addr2;
			let lines = pdf.splitTextToSize(fullAddress, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text

			}
			_yValue += -4; // Decrement Y value for each line of wrapped text
		}

		// Add city, state, and pin information
		if (city.trim() !== "" || state.trim() !== "" || pin.trim() !== "") {
			let _printText =
				(city.trim() !== "" ? city : "") +
				(state.trim() !== "" ? (city !== "" ? ", " : "") + state : "") +
				(pin.trim() !== "" ? " - " + pin : "");
			let lines = pdf.splitTextToSize(_printText, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text
			}
			_yValue += -4; // Add additional spacing after city/state/pin
		}

		// Add phone and mobile information
		if (mobile.trim() !== "" || phone.trim() !== "") {
			let _printText =
				(phone.trim() !== "" ? "Phone: " + phone + "  " : "") +
				(mobile.trim() !== "" ? "Mobile No: " + mobile : "");
			let lines = pdf.splitTextToSize(_printText, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text
			}
			_yValue += -4; // Add additional spacing after phone/mobile
		}

		// Add email information
		if (email.trim() !== "") {
			_yValue += 4; // Increment Y value for spacing
			centerText("Email Id: " + email, _yValue); // Email information			
		}

		// Prepare for the next section (e.g., logo or main content)
		_yValue += 9; // Space for the next section

		// Add logo if available
		var imgData;
		var _logoUrl = ""; // Replace with the actual logo URL
		if (_logoUrl) {
			let _imageId = document.getElementById("imageId");
			_imageId.src = "data:image/png;base64," + _logoUrl; // Set image source
			imgData = document.getElementById("imageId"); // Get image element
			pdf.addImage(imgData, "PNG", 15, _yValue, 40, 20); // Adjust the image position and size
			_yValue += 30; // Adjust Y value based on the image height
		}

		pdf.setFontSize(16);
		// Add the main section title (e.g., kitchenwastage)
		centerText("Kitchen Wastage", _yValue);
		_yValue += 10; // Add spacing after the title
		pdf.setFontSize(10);
		pdf.setTextColor(0, 2, 2);

		// Add kitchenwastage details (login and other info)
		pdf.text(10, _yValue, "Kitchen Wastage Login : " + Login); // Add Login information
		const gstData = this.state.gst;
		var _gstNo = gstData;
		pdf.text(140, _yValue, _gstNo ? "GST No : " + _gstNo : "GST No : "); // GST information

		// Line separator for neatness
		pdf.setDrawColor(0, 0, 0);
		pdf.setLineWidth(0.1);
		pdf.line(8, _yValue + 5, 200, _yValue + 5); // Draw a line

		// Add other kitchenwastage information (e.g., kitchenwastage number, date, etc.)
		_yValue += 10; // Increment Y value for the next section
		pdf.text(10, _yValue, "Wastage No   : " + (this.state.kitchenWastageNo || ""));
		pdf.text(100, _yValue, "Date       : " + (this.state.kitchenWastageDate || ""));
		pdf.text(10, _yValue + 5, "Property     : " + (this.state.selectedPropertyName || ""));
		pdf.text(100, _yValue + 5, "Department : " + (this.state.selectedDepartmentName || ""));
		pdf.text(10, _yValue + 10, "Entered By   : " + (this.state.enterBy || ""));
		var _maxLength = 25; // Maximum number of characters to display
		// If the remarks exceed maxLength, truncate the string
		var _truncatedRemarks = this.state.remarks.length > _maxLength ? this.state.remarks.substring(0, _maxLength) + '' : this.state.remarks;
		pdf.text(100, _yValue + 10, "Remarks    : " + _truncatedRemarks);

		pdf.page = 1;
		var footer = (data) => {
			pdf.setFontSize(8);
			pdf.text(8, 282, 'Page ' + pdf.page);
			pdf.text(130, 282, this.GetCurrentDateTime("Printing Time: ")); //130
			centerText("User: " + sHelpers.secureStorage.getItem("inv.user.name"), 286);
			//pdf.centerAlignText('User: ' + sHelpers.secureStorage.getItem("inv.user.name"), { align: "center" }, 0, 286);
			centerText("License To : " + sHelpers.secureStorage.getItem("inv.lic.name"), 290);
			pdf.page++;
			if (pdf.internal.getNumberOfPages() > 1) {
				pdf.setFontSize(9);
				pdf.text(160, 8, 'KitchenWastage No: ' + (this.state.kitchenWastageNo || ""));
			}
		}



		const options = {

			addPageContent: footer,
			margin: {
				left: 8,
				bottom: 60
			},

			theme: 'plain',
			headerStyles: {

				lineWidth: 0.1,
				lineColor: [0, 0, 0],
				fontStyle: 'bold',
				fontSize: 10,
				halign: 'center'

			},
			tableWidth: '100%',
			styles: {
				cellPadding: 0.6,
				overflow: 'linebreak',
				font: 'courier',
				lineColor: [0, 0, 0],
				lineWidth: 0.1,
				fontSize: 11
			},
			columnStyles: {
				0: { columnWidth: 45 },
				1: { columnWidth: 50 },
				2: { columnWidth: 23, halign: 'left' },
				3: { columnWidth: 23, halign: 'right' },
				4: { columnWidth: 25, halign: 'right' },
				5: { columnWidth: 25, halign: 'right' }
			},

			startY: _yValue + 15

		};

		const tableData = this.state.selectedItems.map((item) => [
			item.TypeName,
			item.ItemName,
			item.Units,
			item.KitchenWastageRate,
			item.KitchenWastageQuantity,
			item.KitchenWastageAmount,
		]);

		// Define columns
		const columns = [
			{ header: 'Type', dataKey: 'typeName' },
			{ header: 'Item Name', dataKey: 'itemName' },
			{ header: 'Units', dataKey: 'units' },
			{ header: 'Rate', dataKey: 'kitchenWastageRate' },
			{ header: 'Qty', dataKey: 'kitchenWastageQuantity' },
			{ header: 'Amount', dataKey: 'kitchenWastageAmount' },
		];

		// Convert tableData into an object format suitable for autoTable
		const tableObjectData = tableData.map((row, index) => ({
			typeName: row[0],
			itemName: row[1],
			units: row[2],
			kitchenWastageRate: row[3],
			kitchenWastageQuantity: row[4],
			kitchenWastageAmount: row[5],
		}));


		//pdf.autoTable(tableColumns, tableData, options);
		//const jsonGrid = this.state.selectedItems
		pdf.autoTable(columns, tableObjectData, options);
		var _startY = pdf.autoTableEndPosY();
		pdf.setDrawColor(0, 0, 0);
		pdf.setLineWidth(0.1);
		this.FindandAddNewPage(pdf, 5);
		pdf.line(8, _startY + 5, 200, _startY + 5);

		// pdf.text(140, _startY + 10, "Total Items :");
		// pdf.text(180, _startY + 10, "" + tableData.length + "");
		this.FindandAddNewPage(pdf, 5);
		pdf.text(10, _startY + 10, 'Total Items :');
		pdf.text(45, _startY + 10, "" + tableData.length + "");

		// Calculate total amount
		const totalAmount = tableObjectData.reduce((total, item) => {
			return total + item.kitchenWastageAmount;
		}, 0);
		pdf.text(165, _startY + 10, 'Total');
		pdf.text(176, _startY + 10, ":");
		pdf.text("" + totalAmount.toFixed(2) + "", 199, _startY + 10, 'right');
		pdf.setLineWidth(0.1);
		pdf.line(8, _startY + 14, 200, _startY + 14);

		var lines = pdf.splitTextToSize('Amount in Words: ' + this.InWordswithDecimal(parseFloat(totalAmount).toFixed(2)), (pdfInMM - lMargin - rMargin));
		pdf.text(8, _startY + 18, lines);
		this.FindandAddNewPage(pdf, 4);
		pdf.setLineWidth(0.1);
		pdf.line(8, _startY + 14, 200, _startY + 14);
		_yValue = _startY + 18;
		this.FindandAddNewPage(pdf, 26);
		pdf.setFontSize(10);
		pdf.text(10, _startY + 30, 'Prepared By');
		pdf.text(150, _startY + 30, 'Store Keeper');
		// Save the PDF
		pdf.save("kitchenwastage.pdf");
	};

	FindandAddNewPage(pdf, addValue) {
		let Login = sHelpers.secureStorage.getItem("inv.user.name") || "super"; // User Login
		_yValue += addValue;

		if (_yValue > 268) {
			pdf.addPage();
			pdf.setFontSize(8);
			pdf.text(8, 282, "Page " + pdf.internal.getNumberOfPages()); // Use internal method for page numbers
			pdf.text(130, 282, this.GetCurrentDateTime("Printing Time: ")); //130

			// Custom implementation to center text
			const centerText = (text, y) => {
				let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
				let textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
				pdf.text(textOffset, y, text);
			};

			// Center-align the text "User: Login"
			centerText("User: " + Login, 286);

			// Center-align the text "License To: Login"
			centerText("License To : " + sHelpers.secureStorage.getItem("inv.lic.name"), 290);

			_yValue = 10; // Reset Y value for new page
		}
	};

	// Function to convert decimal numbers to words
	InWordswithDecimal(n) {
		const nums = n.toString().split('.');
		const whole = this.inWords(nums[0]); // Convert the integer part

		let result = whole;

		if (nums.length === 2) {
			const fraction = this.inWords(nums[1].padEnd(2, '0')); // Convert the fractional part to two digits
			if (fraction !== "") {
				result += ' and ' + fraction + ' Paise'; // Append the fractional part
			}
		}

		return result + ' only.';
	};

	// Helper function to convert integer numbers to words
	inWords(num) {
		const a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ',
			'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
		const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

		if ((num = num.toString()).length > 9) return 'overflow'; // Return overflow for too large numbers
		const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
		if (!n) return ''; // Return empty string if number is not valid

		let str = '';
		str += (n[1] !== '00') ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
		str += (n[2] !== '00') ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
		str += (n[3] !== '00') ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
		str += (n[4] !== '0') ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
		str += (n[5] !== '00') ? ((str !== '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';

		return str;
	};

	GetCurrentDateTime(Inptxt) {
		var currentdate = new Date();
		var hours = currentdate.getHours();
		var ampm = hours >= 12 ? "PM" : "AM";

		var datetime =
			Inptxt +
			currentdate.getDate() +
			"/" +
			(currentdate.getMonth() + 1) +
			"/" +
			currentdate.getFullYear() +
			" " +
			currentdate.getHours() +
			":" +
			currentdate.getMinutes() +
			":" +
			currentdate.getSeconds() +
			" " +
			ampm;

		return datetime;
	};


	imageToBase64(img) {
		var canvas, ctx, dataURL;
		canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);
		dataURL = canvas.toDataURL("image/(png|jpg|jpeg");
		return dataURL;
	};


	goBack() {
		this.props.history.push("/kitchenwastage");
	}

	render() {
		const { paddingTop, paddingBottom } = this.state;

		return (
			<>
				<PageLoading />
				<main
					className="h-100"
					ref={this.mainRef}
					style={{
						paddingTop: `${paddingTop}px`,
						paddingBottom: `${paddingBottom}px`,
					}}
				>
					<header ref={this.headerRef} className="header position-fixed">
						<div className="row">
							<div className="col-auto">
								<button
									className="btn btn-light btn-44 back-btn"
									onClick={this.goBack.bind(this)}
								>
									<i className="bi bi-arrow-left"></i>
								</button>
							</div>
							<div className="col align-self-center text-center">
								<h5>Kitchen Wastage</h5>
							</div>
							{/* <div className="col-auto">
                                <a href="notifications.html" target="_self" className="btn btn-light btn-44">
                                    <i className="bi bi-bell"></i>
                                    <span className="count-indicator"></span>
                                </a>
                            </div> */}
						</div>
					</header>

					<div className="main-container container">
						<div className="card mb-4">
							<div className="card-body">
								<div className="row">
									<div className="col-auto">
										<div className="avatar avatar-60 shadow rounded-10 ">
											<i className="nav-iconbi bi-file-text fw-bold"></i>
										</div>
									</div>
									<div className="col align-self-center ps-0">
										<h5 className="text-color-theme mb-0">
											{this.state.kitchenWastageNo}
										</h5>
										<p className="text-muted">Kitchen Wastage No</p>
									</div>
									<div className="col-auto  align-self-center text-end">
										<h6 className="mb-0">{this.state.kitchenWastageDate}</h6>
										<p className="text-muted">{this.state.kitchenWastageTime}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col">
								<h6 className="title">Header</h6>
							</div>
							<div className="col-auto"></div>
						</div>

						<div className="row mb-3">
							<div className="col">
								<p className="text-muted">Property Name</p>
							</div>
							<div className="col text-left">
								<p className="text-color-theme">
									{this.state.selectedPropertyName}
								</p>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col">
								<p className="text-muted">Remarks</p>
							</div>
							<div className="col text-left">
								<p className="text-color-theme">{this.state.remarks}</p>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col">
								<p className="text-muted">Entered By</p>
							</div>
							<div className="col text-left">
								<p className="text-color-theme">{this.state.enterBy}</p>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col">
								<p className="text-muted">Kitchen Wastage Login</p>
							</div>
							<div className="col text-left">
								<p className="text-color-theme">{this.state.login}</p>
							</div>
						</div>

						<br />
						<div className="row mb-3">
							<div className="col">
								<h6 className="title">
									Detail
									<br />
								</h6>
							</div>
							<div className="col-auto align-self-center">
								<small className="fw-normal text-muted">
									Total Items:{" "}
									<span className="text-color-theme size-16 fw-bold">
										{this.state.selectedItems.length}
									</span>
								</small>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-12 px-0">
								<ul className="list-group list-group-flush scroll-height-300">
									{this.state.selectedItems.map((row) => (
										<li className="list-group-item">
											<div className="row">
												<div className="col-4 align-self-center text-left">
													<p className="text-color-theme mb-0">{row.ItemName}</p>
													<p className="text-muted size-12">{row.StoreName}</p>
												</div>
												<div className="col-4 align-self-center text-left">
													<p className="text-color-theme mb-0">{row.Units}</p>
													<p className="text-muted size-12">Units</p>
												</div>
												<div className="col-4 align-self-center text-left">
													<p className="text-color-theme mb-0">{row.KitchenWastageRate}</p>
													<p className="text-muted size-12">Rate</p>
												</div>
												<div className="col-4 align-self-center text-left">
													<p className="text-color-theme mb-0">
														{row.KitchenWastageQuantity}
													</p>
													<p className="text-muted size-12">Qty</p>
												</div>
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>

						<div className="row">
							<div className="col">
								{this.state.selectedItems && (
									<button
										className="btn btn-light btn-lg shadow-sm w-100"
										onClick={this.handleExportToPdf.bind(this)}
										style={{ display: this.state.btnVisible ? "block" : "none" }}
									>
										<i className="bi bi-file-earmark-pdf me-2"></i>Export
									</button>
								)}
							</div>
						</div>
						<image id="img-print-logo" src="" className="hidden"></image>
						<br />
					</div>
				</main>

			</>
		);
	}
}
export default withAlert()(kitchenwastageprint);
