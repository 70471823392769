import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Config from '../../data/siteconfig';
import SecureStorage from 'secure-web-storage';
let username = '';
let organizationId = '';
let loggedIn = false;
let properties = '';
let stores = '';

var CryptoJS = require("crypto-js");
var SECRET_KEY = Config.secretkey;

class StaticHelpers extends Component {
    static defaultInitiate() {
        //token = this.secureStorage.getItem('club.token');
    }
    static LoggedinInitiate() {
        username = this.secureStorage.getItem('inv.user.name');
        organizationId = this.secureStorage.getItem('inv.organization.id');
        loggedIn = this.secureStorage.getItem('inv.user.loggedin');
        properties = this.secureStorage.getItem('inv.user.properties');
        stores = this.secureStorage.getItem('inv.user.stores');
    }

    // static defaultInputValidation() {
    //     this.defaultInitiate();

    //     if (token === null || token === "") {
    //         return false;
    //     }
    //     return true;
    // }

    static LoggedinInputValidation() {
        // this.defaultInitiate();
        this.LoggedinInitiate();
        // console.log(mobileNo);
        // console.log(membershipNo);
        // console.log(membershipId);
        // console.log(loggedIn);

        if (username === null || username === "") {
            return false;
        }
        else if (loggedIn === null || loggedIn === "") {
            return false;
        }
        else if (organizationId === null || organizationId === "") {
            return false;
        }
        else if (properties === null || properties === "") {
            return false;
        }
        else if (stores === null || stores === "") {
            return false;
        }
        return true;
    }

    static removeStorageData() {

        this.secureStorage.removeItem('inv.user.name');
        this.secureStorage.removeItem('inv.user.role.name');
        this.secureStorage.removeItem('inv.organization.name');
        this.secureStorage.removeItem('inv.organization.id');
        this.secureStorage.removeItem('inv.user.stores');
        this.secureStorage.removeItem('inv.user.properties');
        this.secureStorage.removeItem('inv.user.departments');
        this.secureStorage.removeItem('inv.run.date');
        this.secureStorage.removeItem('inv.server.date');
        this.secureStorage.removeItem('inv.max.run.date');
        this.secureStorage.removeItem('inv.user.loggedin');
        this.secureStorage.removeItem('inv.store.items');

    }

    static secureStorage = new SecureStorage(sessionStorage, {
        hash: function hash(key) {
            key = CryptoJS.SHA256(key, SECRET_KEY);

            return key.toString();
        },
        encrypt: function encrypt(data) {
            data = CryptoJS.AES.encrypt(data, SECRET_KEY);

            data = data.toString();

            return data;
        },
        decrypt: function decrypt(data) {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);

            data = data.toString(CryptoJS.enc.Utf8);

            return data;
        }
    });

    // Move Top
    static mTop() {
        window.scrollTo(0, 0);
    }

    static getCurrentDateTime(Inptxt) {
        let currentdate = new Date();
        let hours = currentdate.getHours();
        let ampm = hours >= 12 ? 'PM' : 'AM';

        let datetime = Inptxt + currentdate.getDate() + "/"
            + (currentdate.getMonth() + 1) + "/"
            + currentdate.getFullYear() + " "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds() + " "
            + ampm;

        return datetime;
    }

    // Custom validation function
    static validateNumeric(value) {
        // Allow empty string for clearing input
        if (value === '') return true;
        // Check if the value is a number
        return !isNaN(value) && !isNaN(parseFloat(value));
    };
}
export default withRouter(StaticHelpers)