import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import './header.scss';
// import sHelpers from '../../modules/helpers/statichelpers';
// import { withAlert } from 'react-alert' // HOC to show the alert message
// import Config from '../../data/siteconfig';
// import Marquee from "react-fast-marquee";

const menuItems = [
    { menuId: 1, menuName: 'Dashboard', iconsName: 'bi bi-house-door', routePath: "/dashboard" },
    // { menuId: 2, menuName: 'My Orders', iconsName: 'fa fa-shopping-cart sidebar-icon', routePath: "/myorders" },
    // // { menuId: 2, menuName: 'My Bills', iconsName: 'fa fa-money', routePath: "/mybills" },
    // // { menuId: 3, menuName: 'Contact Us', iconsName: 'fa fa-phone sidebar-icon', routePath: "/home" },
    { menuId: 2, menuName: 'Variation', iconsName: 'bi bi-file-text', routePath: "/variation" },
    { menuId: 3, menuName: 'Kitchen Closing', iconsName: 'bi bi-file-text', routePath: "/kitchenclosing" },
    { menuId: 4, menuName: 'Kitchen Wastage', iconsName: 'bi bi-file-text', routePath: "/kitchenwastage" },
    { menuId: 5, menuName: 'Inter Department Transfer', iconsName: 'bi bi-file-text', routePath: "/interdepartmenttransfer" },
    // { menuId: 2, menuName: 'Report', iconsName: 'bi bi-calendar', routePath: "/variationreport" },

];

export default class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            active: "dashboard"
        }
    }
    _handleClick(name) {
        this.setState({ active: name });
    }
    render() {

        return (
            <>
                <footer ref={this.props.footerRef} className="footer">
                    <div className="container">
                        <ul className="nav nav-pills nav-justified">
                            {
                                menuItems.map(mnuItem =>
                                    <li key={mnuItem.menuId} className="nav-item">

                                        <NavLink
                                            className={this.state.active === mnuItem.menuName ? "nav-link" : "nav-link"}
                                            onClick={this._handleClick.bind(this, mnuItem.menuName)}
                                            to={mnuItem.routePath}
                                        >

                                            <span>
                                                <i className={"nav-icon" + mnuItem.iconsName}></i>
                                                <span className="nav-text">{mnuItem.menuName}</span>
                                            </span>

                                        </NavLink>

                                    </li>
                                )}

                            {/* 
                            <li className="nav-item">
                                <a className="nav-link active" href="index.html">
                                    <span>
                                        <i className="nav-icon bi bi-grid"></i>
                                        <span className="nav-text">Dashboard</span>
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href="index.html">
                                    <span>
                                        <i className="nav-icon bi bi-file-text"></i>
                                        <span className="nav-text">Variation</span>
                                    </span>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="">
                                    <span>
                                        <i className="nav-icon bi bi-calendar"></i>
                                        <span className="nav-text">Report</span>
                                    </span>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </footer>
            </>
        );
    }
}
