import React from 'react'
// import loading_animation from './loading-bars.svg'
// import styles from './loading.module.sass'
export default function index() {
  return (
    <div className="container-fluid loader-wrap">
      <div className="row h-100">
        <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">
          <div className="loader-cube-wrap loader-cube-animate mx-auto">
            <img src="lucid-inventory.png" alt="Logo" />
          </div>
          <p className="mt-4">Inventory<br /><strong>Please wait...</strong></p>
        </div>
      </div>
    </div>

  )
}
