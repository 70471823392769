import React, { Component, createRef } from "react";
import { withAlert } from "react-alert"; // HOC to show the alert message
import Footer from "../../../component/footer";
import PageLoading from "../../../component/loadinganimation/pageloading";
import LoadingAnimation from "../../../component/loadinganimation/eventloader";
import fetching_animation from '../../../component/loadinganimation/bouncing-circles.svg';
import Config, { lazyLoading } from "../../../data/siteconfig";
import sHelpers from "../../../modules/helpers/statichelpers";
import DateRangePicker from "../../../component/daterangepicker/daterange";
import moment from "moment";

// Page is Created by vijay on 07-10-2024
class kitchenclosing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			paddingTop: 0,
			paddingBottom: 0,
			propertyList: [],
			kitchenList: [],
			fields: {},
			startDate: null,
			endDate: null,
			selectedPropertyCode: 0,
			selectedPropertyName: "",
			pagenumber: 1,
			isLoading: false, // Controls loading animation
			isScrollEnabled: true, // Enables or disables further loading
			resultData: [],
			dashboardData: [],
			selectedKitchenCode: "",
			displayKitchenNo: 0,
		};
		this.mainRef = createRef();
		this.headerRef = createRef();
		this.footerRef = createRef();
		//this.handleScroll = this.handleScroll.bind(this);
		this.handleChangeKitchenList = this.handleChangeKitchenList.bind(this);
	}

	debounce(func, wait) {
		let timeout;
		return function (...args) {
			const context = this; // Capture the context
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				func.apply(context, args); // Call the function with the correct context
			}, wait);
		};
	}

	updateStyles = () => {
		const windowHeight = window.innerHeight;
		const headerHeight = this.headerRef.current
			? this.headerRef.current.offsetHeight
			: 0;
		const footerHeight = this.footerRef.current
			? this.footerRef.current.offsetHeight
			: 0;

		if (this.mainRef.current) {
			this.mainRef.current.style.minHeight = `${windowHeight}px`;
			this.setState({
				paddingTop: headerHeight + 10,
				paddingBottom: footerHeight + 10,
			});
		}
	};

	componentDidMount() {

		//window.addEventListener('scroll', this.debounce(this.handleScroll, 200));
		this.updateStyles();
		window.addEventListener("resize", this.updateStyles);
		if (!sHelpers.LoggedinInputValidation()) {
			this.props.alert.error("Session Expired! Please log in again.");
			this.setState({ loading: true });
			setTimeout(() => {
				this.props.history.push("/login");
			}, Config.minMsgTimeout);
			return false;
		}

		this.setState({
			propertyList: sHelpers.secureStorage.getItem("inv.user.properties"),
		});
	
		window.addEventListener('scroll', this.handleScroll);
		this.handleFilterClick(this);


	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateStyles);
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleChangeProperty(event) {
		event.preventDefault();
		const _filterproperty = sHelpers.secureStorage
			.getItem("inv.user.properties")
			.filter((p) => p.PropertyCode === Number(event.target.value));
		this.setState(
			{
				startDate:
					_filterproperty.length > 0
						? moment(_filterproperty[0].RunDate, "DD/MM/YYYY")
						: null,
				endDate:
					_filterproperty.length > 0
						? moment(_filterproperty[0].RunDate, "DD/MM/YYYY")
						: null,
				selectedPropertyCode: event.target.value,
				selectedPropertyName:
					_filterproperty.length > 0 ? _filterproperty[0].PropertyName : "",
			},
			() => {
				this.getKitchenList(this.state.selectedPropertyCode);
			}
		);

	};

	handleChangeKitchenList = (event) => {
		const selectedKitchenCode = event.target.value;
		this.setState({ selectedKitchenclosingCode: selectedKitchenCode }, () => {
			//console.log("Updated kitchen code:", this.state.selectedKitchenclosingCode); // Logs the updated value
		});
	};

	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	};

	handleStartDatesChange = (date) => {
		this.setState({ startDate: date });
	};

	handleEndDatesChange = (date) => {
		this.setState({ endDate: date });
	};

	validateDateRange(fromDate, toDate) {
		var fromdate = new Date(fromDate);
		var todate = new Date(toDate);
		var timeDiff = Math.abs(todate.getTime() - fromdate.getTime());
		var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Difference in days
		return diffDays <= 30;
	};

	handleFilterClick = async (appendData = false) => {
		try {
			const reportDate = moment(
				moment(
					sHelpers.secureStorage.getItem("inv.max.run.date"),
					"DD/MM/YYYY"
				).subtract(1, "days"),
				"DD/MM/YYY"
			); // One day before today
			//const reportDate = moment(sHelpers.secureStorage.getItem('inv.max.run.date'), "DD/MM/YYYY");
			if (this.state.startDate && this.state.endDate) {
				const fromMoment = moment(this.state.startDate);
				const toMoment = moment(this.state.endDate);
				if (fromMoment.isAfter(toMoment)) {
					this.props.alert.error("From Date must be less than To Date.");
					return false;
				}
			} else if (this.state.startDate && !this.state.endDate) {
				this.props.alert.error("Invalid To Date");
				return false;
			} else if (!this.state.startDate && this.state.endDate) {
				this.props.alert.error("Invalid From Date");
				return false;
			} else if (!this.state.startDate && !this.state.endDate) {
				this.setState({ startDate: reportDate, endDate: reportDate });
			}

			let fields = this.state.fields;
			this.setState({ btnDisabled: true });

			const formatStartDate = this.state.startDate
				? this.state.startDate
				: reportDate;
			const formatEndDate = this.state.endDate
				? this.state.endDate
				: reportDate;

			if (!this.state.fields["kitchenclosingno"]) {
				// Added by vijay on 25-09-2024
				if (!this.validateDateRange(formatStartDate, formatEndDate)) {
					this.props.alert.error("Date range must be within 30 days .");
					//this.setState({ resultData: [] });
					this.setState({ btnDisabled: false });
					return false;
				}
			}
			if (!appendData) {
				this.setState({ resultData: [] });
			}
			//this.setState({ loading: true });
			//this.setState({ isLoading: true });
			const currentPageNo = this.state.pagenumber;

			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			let parameter =
				"?login=" +
				sHelpers.secureStorage.getItem("inv.user.name") +
				"&pagenumber=" +
				currentPageNo +
				"&noofrecords=" +
				lazyLoading.NoOfRecords;
			parameter +=
				"&IsMainUser=" + sHelpers.secureStorage.getItem("inv.ismain.user");
			parameter +=
				"&rightspropertycode=" +
				sHelpers.secureStorage.getItem("inv.rights.property.code");

			if (fields["kitchenclosingno"])
				parameter += "&kitchenclosingno=" + fields["kitchenclosingno"];

			// if (fields["variationno"])
			// 	parameter += "&variationno=" + fields["variationno"];
			if (formatStartDate)
				parameter += "&fromdate=" + formatStartDate.format("YYYY-MM-DD");
			if (formatEndDate)
				parameter += "&todate=" + formatEndDate.format("YYYY-MM-DD");
			if (this.state.selectedKitchenclosingCode) {
				parameter += "&kitchencode=" + this.state.selectedKitchenclosingCode;  // Ensure you append this correctly
			}

			await fetch(Config.baseAPIUrl + "store/kitchenclosings" + parameter, {
				method: "GET",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((response) => {
					//this.setState({ loading: false });
					//console.log('getvariationdashboard', response);
					// if (response.IsSuccessful) {
					// 	this.props.alert.success(response.Message);
					// 	this.setState({
					// 		resultData: response.KitchenClosing,
					// 		dashboardData: response.Dashboard,
					// 	});

					// } else {
					// 	if (response.Message) {
					// 		this.props.alert.error(response.Message);
					// 		this.setState({
					// 			resultData: [],
					// 			dashboardData: response.Dashboard,
					// 		});
					// 	} else {
					// 		this.props.alert.error(response[0]);
					// 	}

					// }
					// this.setState({ btnDisabled: false });
					// setTimeout(() => {
					// 	this.setState({
					// 		loading: false,
					// 	});
					// }, 1000);
					if (response) {
						const dataKitchenClosing = response.KitchenClosing;
						const { pagenumber } = this.state;
						if (pagenumber > 0 && dataKitchenClosing.length === 0) {
							if (response.Message) {
								this.props.alert.error(response.Message);
								this.setState({resultData: [], dashboardData: response.Dashboard, isScrollEnabled: false, isLoading: false,});
								this.props.history.push("/kitchenclosing");
							} else {
								this.props.alert.error(response[0]);
								this.props.history.push("/kitchenclosing");
							}
						}

						else {
							// this.props.alert.success(response.Message);
							const newData = response.KitchenClosing;
							this.setState((prevState) => ({
								resultData: appendData ? [...prevState.resultData, ...newData] : newData,
								dashboardData: response.Dashboard,
							}));

							setTimeout(() => {
								this.setState({
									isLoading: false,
								});
							}, 2000);

							if (newData.length < lazyLoading.NoOfRecords) {
								this.setState({ isScrollEnabled: false }); // Disable scrolling if no more records
							} else {
								this.setState({ isScrollEnabled: true }); // Enable if more records are available
							}
						}
					}
					this.setState({ btnDisabled: false });
				})
				.catch((err) => {
					console.log(err);
					this.setState({ loading: false });
					this.setState({ btnDisabled: false });
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			this.setState({ loading: false });
			this.props.alert.error(error.message);
			// You can set an error state here or handle it as needed
			throw error;
		}
	};

	handleClearClick = () => {
		const reportDate = moment(
			moment(
				sHelpers.secureStorage.getItem("inv.max.run.date"),
				"DD/MM/YYYY"
			).subtract(1, "days"),
			"DD/MM/YYY"
		); // One day before today
		let fields = this.state.fields;
		fields["kitchenclosingno"] = "";
		this.setState({ fields });
		this.setState({ startDate: reportDate, endDate: reportDate });
		setTimeout(() => {
			this.handleFilterClick(this);
		}, Config.minMsgTimeout);
	};

	goBack() {
		this.props.history.push("/dashboard");
	};

	gotoEntry(KitchenClosingNo, mode) {
		this.props.history.push("/kitchenclosingentry?" + mode, {
			kitchenclosingno: KitchenClosingNo,
			mode: mode,
		});
	};

	getKitchenList = async (propertyCode) => {

		let RequestIP = '127.0.0.0';

		//console.log(propertyCode);
		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(Config.baseAPIUrl + "common/propertykitchens?" +
				"propertycode=" + propertyCode +
				"&requestip=" + RequestIP, {
				method: "GET",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((response) => {
					//console.log("getpropertykitchens", response);
					if (response.IsSuccessful && response.Kitchens.length > 0) {
						this.setState({ kitchenList: response.Kitchens });
					} else {

						// this.props.alert.error(response.Message);
						this.setState({ kitchenList: [] });
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	gotoEdit(KitchenClosingNo, mode) {
		this.props.history.push("/kitchenclosingentry", {
			kitchenclosingno: KitchenClosingNo,
			mode: mode,
		});
	};

	gotoPrint(KitchenClosingNo, mode) {
		this.props.history.push("/kitchenclosing-print?" + mode, {
			KitchenClosingNo: KitchenClosingNo,
			mode: mode,
		});
	};

	handleScroll = () => {
		const { isLoading, isScrollEnabled, pagenumber } = this.state;
		if (
			isScrollEnabled &&
			!isLoading &&
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 100
		) {
			this.setState({ isLoading: true }, () => {
				this.loadMoreData(pagenumber + 1);
			});
		}
	};

	loadMoreData = async (nextPageNumber) => {
		this.setState({ pagenumber: nextPageNumber });
		if (this.state.pagenumber > 1) {
			await this.handleFilterClick(true); // Append data
		} else {
			this.setState({ isLoading: false });
		}
	};


	render() {
		const { paddingTop, paddingBottom } = this.state;
		const { startDate, endDate } = this.state;

		return (
			<>
				{this.state.loading && <LoadingAnimation />}
				<PageLoading />
				<main
					className="h-100"
					ref={this.mainRef}
					style={{
						paddingTop: `${paddingTop}px`,
						paddingBottom: `${paddingBottom}px`,
					}}
				>
					<header ref={this.headerRef} className="header position-fixed">
						<div className="row">
							<div className="col-auto">
								<button
									className="btn btn-light btn-44 back-btn"
									onClick={this.goBack.bind(this)}
								>
									<i className="bi bi-arrow-left"></i>
								</button>
							</div>
							<div className="col align-self-center text-center">
								<h5> Kitchen Closing</h5>
							</div>
						</div>
					</header>

					<div className="main-container container">
						<div className="row mb-3">
							<div className="col">
								<h6 className="title">Kitchen Closing List</h6>
							</div>
							<div
								className="col-auto align-self-center"
								onClick={this.gotoEntry.bind(this, 0, "New")}
							>
								{/* <a href="#" className="small">New Variation</a> */}
								<button
									className="btn-default btn btn-sm shadow-sm avatar me-2 text-white w-100"
									id="addtohome"
									disabled={this.state.btnDisabled}
								>
									<i className="bi bi-plus me-2"></i> Add New
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6">
								<div className="card mb-4">
									<div className="card-header border-0">
										<div className="col-12 col-md-12">
											<div className="form-group form-floating  mb-3">
												<input
													type="text"
													className="form-control"
													placeholder="Closing No"
													id="closing-no"
													onChange={this.handleChange.bind(this, "kitchenclosingno")}
													value={this.state.fields["kitchenclosingno"]}
													maxLength="30"
												/>
												<label htmlFor="closing-no">Closing No</label>
											</div>
										</div>
										<div className="row">
											<DateRangePicker
												startDateValue={startDate}
												endDateValue={endDate}
												onStartDatesChange={this.handleStartDatesChange}
												onEndDatesChange={this.handleEndDatesChange}
											/>
										</div>

										<div className="col-12 col-md-12">
											<div className="form-floating mb-3 is-valid">
												<select
													className="form-control"
													id="kitchen-properties"
													onChange={this.handleChangeProperty.bind(this)}
												>
													<option value="0" key="0">
														{" "}
														All
													</option>
													{this.state.propertyList.map((prop) => (
														<option
															value={prop.PropertyCode}
															key={prop.PropertyCode}
														>
															{prop.PropertyName}
														</option>
													))}
												</select>
												<label htmlFor="country">Property</label>
											</div>
										</div>

										<div className="col-12 col-md-12">
											<div className="form-floating mb-3 is-valid">
												<select
													className="form-control"
													id="kitchen-lists"
													onChange={this.handleChangeKitchenList.bind(this)}
													value={this.state.selectedKitchenclosingCode}
												//  disabled={this.state.ctrlDisabled ||this.state.kitchenList.length === 0}
												>
													<option value="0" key="0">
														All
													</option>
													{this.state.kitchenList &&
														this.state.kitchenList.length > 0 &&
														this.state.kitchenList.map((kitchen) => (
															<option
																value={kitchen.KitchenCode}
																key={kitchen.KitchenCode}
															>
																{kitchen.KitchenName}
															</option>
														))}
												</select>
												<label htmlFor="country">KitchenList</label>
											</div>
										</div>

										<div className="row">
											<div className="col-6 col-md-6">
												<button
													className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
													disabled={this.state.btnDisabled}
													onClick={() => this.handleFilterClick(false)}
												>
													<i className="bi bi-search me-2"></i> Filter
												</button>
											</div>
											<div className="col-6 col-md-6">
												<button
													className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
													disabled={this.state.btnDisabled}
													onClick={this.handleClearClick.bind(this)}
												>
													<i className="bi bi-arrow-counterclockwise me-2"></i>{" "}
													Clear
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-6">
								<div className="row">
									<div className="col-6 col-md-12">
										<div className="card shadow-sm mb-4">
											<div className="card-body">
												<div className="row">
													<div className="col-auto">
														<div className="circle-small">
															<div id="circleprogressone"></div>
															<div className="avatar avatar-30 alert-primary text-primary rounded-circle">
																<i className="bi bi-file-text-fill"></i>
															</div>
														</div>
													</div>
													<div className="col px-0 align-self-center">
														<p className="text-muted size-12 mb-0">
															Closings
														</p>
														<p>
															{this.state.dashboardData
																? this.state.dashboardData.TotalKitchenClosing
																: 0}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col">
								{this.state.resultData.map((row) => (
									<div className="card mb-3">
										<div className="card-body">
											<div className="row">
												<div className="col-auto">
													<div className="avatar avatar-50 shadow rounded-10 ">
														<p>{row.KitchenClosingDisplayNo}</p>
													</div>
												</div>
												<div className="col align-self-center ps-0">
													<p className="text-color-theme small mb-1 fw-medium">
														{row.PropertyName}
														{/* <br /> <span className="text-muted">request</span> */}
													</p>
													<p className="text-muted mb-0">
														Kitchen:{" "}
														<span className="text-color-theme fw-medium">
															{row.KitchenName}
														</span>
													</p>
													<p>
														<span className="text-muted">{row.CreatedBy}</span>{" "}
														<small className="text-muted">
															{row.KitchenClosingDateTime}
														</small>
													</p>
												</div>
												<div className="col-auto">
													<button
														className="btn btn-44 btn-light text-danger"
														onClick={this.gotoEntry.bind(
															this,
															row.KitchenClosingNo,
															"View"
														)}
													>
														<i className="bi bi-eye"></i>
													</button>
													<button
														className="btn btn-44 btn-light text-danger"
														onClick={this.gotoEntry.bind(
															this,
															row.KitchenClosingNo,
															"Delete"
														)}
													>
														<i className="bi bi-trash"></i>
													</button>
													<button
														className="btn btn-44 btn-light btn-lg"
														onClick={this.gotoEdit.bind(
															this,
															row.KitchenClosingNo,
															"Edit"
														)}
													>
														<i className="bi bi-pencil"></i>
													</button>
													<button
														className="btn btn-44 btn-light btn-lg"
														onClick={this.gotoPrint.bind(
															this,
															row.KitchenClosingNo,
															"Print"
														)}
													>
														<i className="bi bi-printer"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								))}
								{this.state.isLoading && (
									<div id="page-record-loader" className="text-center loader-container">
										<img src={fetching_animation} alt="Loading..." width={50} height={50} />
										<span>   Fetching</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</main>

				<Footer footerRef={this.footerRef} />
			</>
		);
	}
}
export default withAlert()(kitchenclosing);
