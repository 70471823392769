import React, { Component, createRef } from "react";
import { withAlert } from "react-alert"; // HOC to show the alert message
import Config, { entryMode, printSetting } from "../../../data/siteconfig";
import sHelpers from "../../../modules/helpers/statichelpers";
import PageLoading from "../../../component/loadinganimation/pageloading";
import "./kitchenclosing.scss";
import "./autocomplete.scss";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
let _yValue = printSetting.logoy;

// Page is Created by vijay on 07-10-2024
class kitchenclosingprint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			paddingTop: 0,
			paddingBottom: 0,
			selectedPropertyCode: 0,
			selectedPropertyName: "",
			KitchenName: "",
			KitchenCode: 0,
			KitchenNo: 0,
			KitchenclosingNo: "-",
			KitchenClosingDate: "",
			KitchenclosingTime: "-",
			selectedItems: [],
			remarks: "-",
			enteredBy: "-",
			login: "-",
			btnDisabled: false,
			btnVisible: true,
			selectedItemValue: "",
			gst: "-",
			footer: '',
			city: '',
			state: '',
			pin: '',
			mobile: '',
			phone: '',
			email: '',
			address1: '',
			address2: '',
		};
		this.mainRef = createRef();
		this.headerRef = createRef();
		this.footerRef = createRef();
		this.itemSearchRef = React.createRef();
		this.qtyRef = React.createRef();
		this.enteredByRef = React.createRef();
	}

	updateStyles = () => {
		const windowHeight = window.innerHeight;
		const headerHeight = this.headerRef.current
			? this.headerRef.current.offsetHeight
			: 0;
		const footerHeight = this.footerRef.current
			? this.footerRef.current.offsetHeight
			: 0;

		if (this.mainRef.current) {
			this.mainRef.current.style.minHeight = `${windowHeight}px`;
			this.setState({
				paddingTop: headerHeight + 10,
				paddingBottom: footerHeight + 10,
			});
		}
	};

	componentDidMount() {
		this.updateStyles();
		window.addEventListener("resize", this.updateStyles);

		if (!sHelpers.LoggedinInputValidation()) {
			this.props.alert.error("Session Expired! Please log in again.");
			this.setState({ loading: true });
			setTimeout(() => {
				this.props.history.push("/login");
			}, Config.minMsgTimeout);
			return false;
		}

		if (this.props.location.state) {
			this.setState({ KitchenClosingNo: this.props.location.state.KitchenClosingNo });
			setTimeout(() => {
				if (this.props.location.state.mode === entryMode.print) {
					this.getKitchenPrint(this.props.location.state.KitchenClosingNo);
				} else {
					this.props.history.push("/kitchenclosing");
				}
			}, Config.minMsgTimeout);

			window.scrollTo(0, 0);
		} else {
			setTimeout(() => {
				this.props.history.push("/kitchenclosing");
			}, Config.minMsgTimeout);
		}

		// handleMode
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateStyles);
	}

	handleScrollIntoView(scrollRef) {
		window.scrollTo(0, 0);
		setTimeout(() => {
			scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}, Config.averageMsgTimeout);
	};

	getKitchenPrint = async (KitchenClosingNo) => {
		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(Config.baseAPIUrl + "store/kitchenclosings/print/" + KitchenClosingNo, {
				method: "GET",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((response) => {
					console.log("kitchenclosings", response);
					if (response.IsSuccessful) {
						// Store Detail values in the state for rendering
						//	 this.setState({ variationHeader: response.Header });
						this.setState({ selectedItems: response.Detail });
						this.setState({
							KitchenClosingNo: response.Header.DisplayKitchenClosingNo,
							DisplayKitchenClosingNo: response.Header.DisplayKitchenClosingNo,
							KitchenClosingDate: response.Header.KitchenClosingDate,
							KitchenclosingTime: response.Header.KitchenClosingTime,
							KitchenName: response.Header.KitchenName,
							KitchenCode: response.Header.KitchenCode,
							selectedPropertyName: response.Header.PropertyName,
							selectedPropertyCode: response.Header.PropertyCode,
							login: response.Header.Login,
							logourl: response.Header.LogoUrl,
							address1: response.Header.Address1,
							address2: response.Header.Address2,
							city: response.Header.City,
							state: response.Header.State,
							pin: response.Header.ZipCode,
							email: response.Header.Email,
							selectedItems: response.Detail,
							mobile: response.Header.Mobile,
							phone: response.Header.Phone,
							gst: response.Header.GST,
							Website: response.Header.Website,
						});
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	handleExportToPdf = () => {

		const lMargin = 15; // Left margin in mm
		const rMargin = 15; // Right margin in mm
		const pdfInMM = 210; // Width of A4 in mm
		let pdf = new jsPDF("p", "mm", "a4"); // Initialize jsPDF instance
		_yValue = printSetting.logoy;

		if (this.state.logoUrl) {
			const _imgData = "data:image/png;base64," + this.state.logoUrl + "";
			pdf.addImage(_imgData, 'JPEG', printSetting.logox, printSetting.logoy, printSetting.logowidth, printSetting.logoheight);
		}

		pdf.setFont("courier");
		pdf.setFontSize(16);

		_yValue += 20;

		const centerText = (text, y) => {
			let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
			let textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
			pdf.text(textOffset, y, text);
		};

		const propertyName = this.state.selectedPropertyName || "Property Name";
		centerText(propertyName, _yValue);
		pdf.setFontSize(12);

		// Update address and other details from state or default values
		let addr1 = String(this.state.address1 || "");
		let addr2 = String(this.state.address2 || "");
		let city = String(this.state.city || "");
		let state = String(this.state.state || "");
		let pin = String(this.state.pin || "");
		let mobile = String(this.state.mobile || "");
		let phone = String(this.state.phone || "");
		let email = String(this.state.email || "");
		let Login = sHelpers.secureStorage.getItem("inv.user.name") || "super"; // User Login

		if (addr1.trim() !== "" || addr2.trim() !== "") {
			let fullAddress = addr1 + " " + addr2;
			let lines = pdf.splitTextToSize(fullAddress, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text

			}
			_yValue += -4;
		}

		if (city.trim() !== "" || state.trim() !== "" || pin.trim() !== "") {
			let _printText =
				(city.trim() !== "" ? city : "") +
				(state.trim() !== "" ? (city !== "" ? ", " : "") + state : "") +
				(pin.trim() !== "" ? " - " + pin : "");
			let lines = pdf.splitTextToSize(_printText, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text
			}
			_yValue += -4; // Add additional spacing after city/state/pin
		}

		if (mobile.trim() !== "" || phone.trim() !== "") {
			let _printText =
				(phone.trim() !== "" ? "Phone: " + phone + "  " : "") +
				(mobile.trim() !== "" ? "Mobile No: " + mobile : "");
			let lines = pdf.splitTextToSize(_printText, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text
			}
			_yValue += -4; // Add additional spacing after phone/mobile
		}

		if (email.trim() !== "") {
			_yValue += 4; // Increment Y value for spacing
			centerText("Email Id: " + email, _yValue); // Email information			
		}

		_yValue += 9; // Space for the next section

		var imgData;
		var _logoUrl = "";
		if (_logoUrl) {
			let _imageId = document.getElementById("imageId");
			_imageId.src = "data:image/png;base64," + _logoUrl;
			imgData = document.getElementById("imageId");
			pdf.addImage(imgData, "PNG", 15, _yValue, 40, 20);
			_yValue += 30;
		}

		pdf.setFontSize(16);
		centerText("Kitchen Closing", _yValue);
		_yValue += 8;
		pdf.setFontSize(10);
		pdf.setTextColor(0, 2, 2);

		pdf.text(10, _yValue, "KitchenClosing Login : " + Login);
		const gstData = this.state.gst;
		var _gstNo = gstData;
		pdf.text(140, _yValue, _gstNo ? "GST No : " + _gstNo : "GST No : ");

		pdf.setDrawColor(0, 0, 0);
		pdf.setLineWidth(0.1);
		pdf.line(8, _yValue + 5, 200, _yValue + 5); // Draw a line

		_yValue += 10; // Increment Y value for the next section
		pdf.text(10, _yValue, "Closing No : " + (this.state.KitchenClosingNo || "N/A"));
		pdf.text(100, _yValue, "Date       : " + (this.state.KitchenClosingDate || "N/A"));
		pdf.text(10, _yValue + 5, "Property   : " + (this.state.selectedPropertyName || "N/A"));
		pdf.text(100, _yValue + 5, "KitchenName: " + (this.state.KitchenName || "N/A"));


		pdf.page = 1;
		var footer = (data) => {
			pdf.setFontSize(8);
			pdf.text(8, 282, 'Page ' + pdf.page);
			pdf.text(130, 282, this.GetCurrentDateTime("Printing Time: ")); //130
			centerText("User: " + sHelpers.secureStorage.getItem("inv.user.name"), 286);
			centerText("License To : " + sHelpers.secureStorage.getItem("inv.lic.name"), 290);
			pdf.page++;
		}

		const options = {

			addPageContent: footer,
			margin: {
				left: 8,
				bottom: 60
			},
			theme: 'plain',
			headerStyles: {

				lineWidth: 0.1,
				lineColor: [0, 0, 0],
				fontStyle: 'bold',
				fontSize: 10,
				halign: 'center'
			},
			tableWidth: '100%',
			styles: {
				cellPadding: 0.6,
				overflow: 'linebreak',
				font: 'courier',
				lineColor: [0, 0, 0],
				lineWidth: 0.1,
				fontSize: 11
			},
			columnStyles: {
				0: { columnWidth: 90 },
				1: { columnWidth: 23, halign: "left" },
				2: { columnWidth: 23, halign: "left" },
				3: { columnWidth: 23, halign: "right" },
				4: { columnWidth: 35, halign: "right" },
			},

			startY: _yValue + 15
		};

		const tableData = this.state.selectedItems.map((item) => [
			item.ItemName,
			item.Units,
			item.KitchenClosingRate,
			item.KitchenClosingQuantity,
			item.KitchenClosingAmount,
		]);

		const columns = [
			{ header: 'Item Name', dataKey: 'itemName' },
			{ header: 'Units', dataKey: 'units' },
			{ header: 'Item Rate', dataKey: 'itemRate' },
			{ header: 'Qty', dataKey: 'kitchenQty' },
			{ header: 'Amount', dataKey: 'kitchenAmount' },
		];

		const tableObjectData = tableData.map((row, index) => ({
			itemName: row[0],
			units: row[1],
			itemRate: row[2],
			kitchenQty: row[3],
			kitchenAmount: row[4],
		}));


		pdf.autoTable(columns, tableObjectData, options);
		var _startY = pdf.autoTableEndPosY();
		pdf.setDrawColor(0, 0, 0);
		pdf.setLineWidth(0.1);
		pdf.line(8, _startY + 5, 200, _startY + 5);


		pdf.text(10, _startY + 10, 'Total Items :');
		pdf.text(45, _startY + 10, "" + tableData.length + "");

		// Calculate total amount
		const totalAmount = tableObjectData.reduce((total, item) => {
			return total + item.kitchenAmount;
		}, 0);
		pdf.text(165, _startY + 10, 'Total');
		pdf.text(176, _startY + 10, ":");
		pdf.text("" + totalAmount.toFixed(3) + "", 199, _startY + 10, 'right');

		pdf.setLineWidth(0.1);
		pdf.line(8, _startY + 14, 200, _startY + 14);

		var lines = pdf.splitTextToSize('Amount in Words: ' + this.InWordswithDecimal(parseFloat(totalAmount).toFixed(2)), (pdfInMM - lMargin - rMargin));
		pdf.text(8, _startY + 18, lines);

		pdf.setLineWidth(0.1);
		pdf.line(8, _startY + 14, 200, _startY + 14);
		_yValue = _startY + 18;
		this.FindandAddNewPage(pdf, 30);
		pdf.setFontSize(10);
		pdf.text(10, _startY + 30, 'Prepared By');
		pdf.text(150, _startY + 30, 'Store Keeper');
		// Save the PDF
		pdf.save("KitchenClosing.pdf");
	};


	// Function to convert decimal numbers to words
	InWordswithDecimal(n) {
		const nums = n.toString().split('.');
		const whole = this.inWords(nums[0]); // Convert the integer part

		let result = whole;

		if (nums.length === 2) {
			const fraction = this.inWords(nums[1].padEnd(2, '0')); // Convert the fractional part to two digits
			if (fraction !== "") {
				result += ' and ' + fraction + ' Paise'; // Append the fractional part
			}
		}

		return result + ' only.';
	};

	// Helper function to convert integer numbers to words
	inWords(num) {
		const a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ',
			'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
		const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

		if ((num = num.toString()).length > 9) return 'overflow'; // Return overflow for too large numbers
		const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
		if (!n) return ''; // Return empty string if number is not valid

		let str = '';
		str += (n[1] !== '00') ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
		str += (n[2] !== '00') ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
		str += (n[3] !== '00') ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
		str += (n[4] !== '0') ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
		str += (n[5] !== '00') ? ((str !== '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';

		return str;
	};

	FindandAddNewPage(pdf, addValue) {
		let Login = sHelpers.secureStorage.getItem("inv.user.name") || "super"; // User Login
		_yValue += addValue;

		if (_yValue > 268) {
			pdf.addPage();
			pdf.setFontSize(8);
			pdf.text(8, 282, "Page " + pdf.internal.getNumberOfPages()); // Use internal method for page numbers
			pdf.text(130, 282, this.GetCurrentDateTime("Printing Time: ")); //130

			const centerText = (text, y) => {
				let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
				let textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
				pdf.text(textOffset, y, text);
			};

			centerText("User: " + Login, 286);
			centerText("License To : " + sHelpers.secureStorage.getItem("inv.lic.name"), 290);

			_yValue = 10;
		}
	};

	GetCurrentDateTime(Inptxt) {
		var currentdate = new Date();
		var hours = currentdate.getHours();
		var ampm = hours >= 12 ? "PM" : "AM";

		var datetime =
			Inptxt +
			currentdate.getDate() +
			"/" +
			(currentdate.getMonth() + 1) +
			"/" +
			currentdate.getFullYear() +
			" " +
			currentdate.getHours() +
			":" +
			currentdate.getMinutes() +
			":" +
			currentdate.getSeconds() +
			" " +
			ampm;

		return datetime;
	};

	imageToBase64(img) {
		var canvas, ctx, dataURL;
		canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);
		dataURL = canvas.toDataURL("image/(png|jpg|jpeg");
		return dataURL;
	};

	goBack() {
		this.props.history.push("/kitchenclosing");
	};

	render() {
		const { paddingTop, paddingBottom } = this.state;

		return (
			<>
				<PageLoading />
				<main
					className="h-100"
					ref={this.mainRef}
					style={{
						paddingTop: `${paddingTop}px`,
						paddingBottom: `${paddingBottom}px`,
					}}
				>
					<header ref={this.headerRef} className="header position-fixed">
						<div class="row">
							<div class="col-auto">
								<button
									class="btn btn-light btn-44 back-btn"
									onClick={this.goBack.bind(this)}
								>
									<i class="bi bi-arrow-left"></i>
								</button>
							</div>
							<div class="col align-self-center text-center">
								<h5>Kitchen Closing</h5>
							</div>
							{/* <div class="col-auto">
                                <a href="notifications.html" target="_self" class="btn btn-light btn-44">
                                    <i class="bi bi-bell"></i>
                                    <span class="count-indicator"></span>
                                </a>
                            </div> */}
						</div>
					</header>

					<div class="main-container container">
						<div class="card mb-4">
							<div class="card-body">
								<div class="row">
									<div class="col-auto">
										<div class="avatar avatar-60 shadow rounded-10 ">
											<i class="nav-iconbi bi-file-text fw-bold"></i>
										</div>
									</div>
									<div class="col align-self-center ps-0">
										<h5 class="text-color-theme mb-0">
											{this.props.location.state.KitchenClosingNo}
										</h5>
										<p class="text-muted">Kitchen No</p>
									</div>
									<div class="col-auto  align-self-center text-end">
										<h6 class="mb-0">{this.state.KitchenClosingDate}</h6>
										<p class="text-muted">{this.state.KitchenclosingTime}</p>
									</div>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col">
								<h6 class="title">Header</h6>
							</div>
							<div class="col-auto"></div>
						</div>

						<div class="row mb-3">
							<div class="col">
								<p class="text-muted">Property Name</p>
							</div>
							<div class="col text-end">
								<p className="text-color-theme">
									{this.state.selectedPropertyName}
								</p>
							</div>
						</div>
						<div class="row mb-3">
							<div class="col">
								<p class="text-muted">Kitchen Name</p>
							</div>
							<div class="col text-end">
								<p className="text-color-theme">{this.state.KitchenName}</p>
							</div>
						</div>
						{/* <div class="row mb-3">
							<div class="col">
								<p class="text-muted">Type</p>
							</div>
							<div class="col text-end">
								<p className="text-color-theme">{this.state.enteredby}</p>
							</div>
						</div> */}
						<div class="row mb-3">
							<div class="col">
								<p class="text-muted">Kitche Closing Login</p>
							</div>
							<div class="col text-end">
								<p className="text-color-theme">{this.state.login}</p>
							</div>
						</div>

						<br />
						<div class="row mb-3">
							<div class="col">
								<h6 class="title">
									Detail
									<br />
								</h6>
							</div>
							<div class="col-auto align-self-center">
								<small class="fw-normal text-muted">
									Total Items:{" "}
									<span className="text-color-theme size-16 fw-bold">
										{this.state.selectedItems.length}
									</span>
								</small>
							</div>
						</div>
						<div class="row mb-4">
							<div class="col-12 px-0">
								<ul class="list-group list-group-flush scroll-height-300">
									{this.state.selectedItems.map((row) => (
										<li class="list-group-item">
											<div class="row">
												<div class="col-auto align-self-center text-end">
													<p class="text-color-theme mb-0">{row.ItemName}</p>
													<p class="text-muted size-12">{row.StoreName}</p>
												</div>
												<div class="col align-self-center text-end">
													<p class="text-color-theme mb-0">{row.Units}</p>
													<p class="text-muted size-12">Units</p>
												</div>
												<div class="col align-self-center text-end">
													<p class="text-color-theme mb-0">{row.KitchenClosingRate}</p>
													<p class="text-muted size-12">Rate</p>
												</div>
												<div class="col align-self-center text-end">
													<p class="text-color-theme mb-0">
														{row.KitchenClosingQuantity}
													</p>
													<p className="text-muted size-12">Qty</p>
												</div>
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>

						<div class="row">
							<div class="col">
								{this.state.selectedItems && (
									<button
										class="btn btn-light btn-lg shadow-sm w-100"
										onClick={this.handleExportToPdf.bind(this)}
									>
										<i class="bi bi-file-earmark-pdf me-2"></i>Export
									</button>
								)}
							</div>
						</div>
						<br />
					</div>
				</main>
			</>
		);
	}
}
export default withAlert()(kitchenclosingprint);
