import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import { positions, Provider } from "react-alert";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from "react-alert-template-basic";
// import ErrorBoundary from './view/error/pageerror';
// import ErrorBoundary from './component/error/errorboundary';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

//import { Provider } from 'react-redux';

// Alert Message Popup
const options = {
    timeout: 3000,
    position: positions.TOP_CENTER,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
};

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
ReactDOM.render(
    // <Provider store={store}>
    //Alert Message Popup
    <AlertProvider template={AlertTemplate} {...options}>
        {/* <ErrorBoundary> later add to the error handling */}
        <App />
        {/* </ErrorBoundary> */}
    </AlertProvider>,
    // </Provider>,
    document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
serviceWorker.unregister();
