import React from 'react';
import loading_animation from './loading-bars.svg';
import styles from './login.module.sass';
export default function eventloader() {
  return (
    <div className={styles.loading_background} >
      <img src={loading_animation} className={styles.svg} alt="" />
    </div>
  )
}
