//import logo from './logo.svg';
//import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";// JK Custom
import Home from './view/home';
import Register from './view/register';
import Login from './view/login';
import LoginVerification from './view/verification/loginverification';
import Dashboard from './view/dashboard';
import Logout from './view/logout';
import PaymentProcess from './view/payment';
import PaymentVerification from './view/payment/paymentverification';
import PaymentFailed from './view/payment/paymentfailed';
import PaymentSuccess from './view/payment/paymentsuccess';
import splash from './view/splashscreen';
import PageError from './view/error/pageerror';
import ForgotPassword from './view/forgotpassword';
import ConfirmPassword from './view/forgotpassword/confirmpassword';

// Page
import PageNormal from './view/page'; // normal 
import PageGallery from './view/page/gallery'; // gallery 
import PageList from './view/page/list'; // list page 
import PageContact from './view/page/contact'; // contact
import PageMember from './view/page/member'; // member

// stores
import Variation from './view/stores/variation';
import VariationEntry from './view/stores/variation/entry';
import VariationPrint from './view/stores/variation/print';

import KitchenWastage from './view/stores/kitchenwastage';
import KitchenWastageEntry from './view/stores/kitchenwastage/entry';
import KitchenWastagePrint from "./view/stores/kitchenwastage/print";

//kitchen closing
import Kitchenclosing from "./view/stores/kitchenclosing";
import kitchenclosingEntry from "./view/stores/kitchenclosing/entry";
import kitchenclosingPrint from "./view/stores/kitchenclosing/print";


import InterDepartmentTransfer from './view/stores/interdepartmenttransfer';
import InterDepartmentTransferEntry from './view/stores/interdepartmenttransfer/entry';
import InterDepartmentTransferPrint from "./view/stores/interdepartmenttransfer/print";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route component={Home} exact path="/home" ></Route>
          <Route component={splash} exact path="/" ></Route>
          <Route key="dashboard" component={Dashboard} path="/dashboard" exact></Route>

          {/* variation-start */}
          <Route key="variation" component={Variation} path="/variation" exact></Route>
          <Route key="variation-entry" component={VariationEntry} path="/variation-entry" exact></Route>
          <Route key="variation-print" component={VariationPrint} path="/variation-print" exact></Route>
          {/* variation-end */}

          {/* kitchenwastage-start */}
          <Route key="kitchenwastage" component={KitchenWastage} path="/kitchenwastage" exact></Route>
          <Route key="kitchenwastage-entry" component={KitchenWastageEntry} path="/kitchenwastage-entry" exact></Route>
          <Route key="kitchenwastage-print" component={KitchenWastagePrint} path="/kitchenwastage-print" exact></Route>
          {/* kitchenwastage-end */}

          {/* kitchenwastage-start */}
          <Route key="interdepartmenttransfer" component={InterDepartmentTransfer} path="/interdepartmenttransfer" exact></Route>
          <Route key="interdepartmenttransfer-entry" component={InterDepartmentTransferEntry} path="/interdepartmenttransfer-entry" exact></Route>
          <Route key="interdepartmenttransfer-print" component={InterDepartmentTransferPrint} path="/interdepartmenttransfer-print" exact></Route>
          {/* kitchenwastage-end */}

          {/* // Added by vijay  */}
          {/* kitchenclosing-start */}
          <Route
            key="kitchenclosing"
            component={Kitchenclosing}
            path="/kitchenclosing"
            exact
          ></Route>
          <Route
            key="kitchenclosingentry"
            component={kitchenclosingEntry}
            path="/kitchenclosingentry"
            exact
          ></Route>
          <Route
            key="kitchenclosing-print"
            component={kitchenclosingPrint}
            path="/kitchenclosing-print"
            exact
          ></Route>
          {/* kitchenclosing-end */}



          <Route key="register" component={Register} path="/register" exact></Route>
          <Route key="login" component={Login} path="/login" exact></Route>
          <Route key="loginverification" component={LoginVerification} path="/loginverification" exact></Route>

          <Route key="logout" component={Logout} path="/logout" exact></Route>
          <Route key="paymentprocess" component={PaymentProcess} path="/paymentprocess" exact></Route>
          <Route key="paymentverification" component={PaymentVerification} path="/paymentverification" exact></Route>
          <Route key="paymentfailed" component={PaymentFailed} path="/paymentfailed" exact></Route>
          <Route key="paymentsuccess" component={PaymentSuccess} path="/paymentsuccess" exact></Route>
          <Route key="pageerror" component={PageError} path="/pageerror" exact></Route>
          <Route key="forgotpassword" component={ForgotPassword} path="/forgotpassword" exact></Route>
          <Route key="confirmpassword" component={ConfirmPassword} path="/confirmpassword" exact></Route>
          <Route key="page" component={PageNormal} path="/page" exact></Route>
          <Route key="gallery" component={PageGallery} path="/gallery" exact></Route>
          <Route key="list" component={PageList} path="/list" exact></Route>
          <Route key="contact" component={PageContact} path="/contact" exact></Route>
          <Route key="member" component={PageMember} path="/member" exact></Route>
          <Redirect to='/' />
        </Switch>
      </Router>
      {/* <Header></Header> */}
      {/* <Slider></Slider> */}
    </div>
  );
}

export default App;
