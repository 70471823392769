import React, { Component, createRef } from 'react';
// import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
// import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' // HOC to show the alert message
//import EConfig from '../../data/enumconfig';
//let token = '';
const currentYear = new Date().getFullYear();
class login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            loading: false,
            fields: {},
            passwordShown: false,
            paddingTop: 0,
            paddingBottom: 0,
            btnDisabled: false,
            btnLoginText: "Login"
        }
        this.mainRef = createRef();
        this.headerRef = createRef();
        this.footerRef = createRef();
    }


    componentDidMount() {
        this.updateStyles();
        window.addEventListener('resize', this.updateStyles);
        sHelpers.removeStorageData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateStyles);
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        console.log(errorInfo);
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    updateStyles = () => {
        const windowHeight = window.innerHeight;
        const headerHeight = this.headerRef.current ? this.headerRef.current.offsetHeight : 0;
        const footerHeight = this.footerRef.current ? this.footerRef.current.offsetHeight : 0;

        if (this.mainRef.current) {
            this.mainRef.current.style.minHeight = `${windowHeight}px`;
            this.setState({
                paddingTop: headerHeight + 10,
                paddingBottom: footerHeight + 10
            });
        }
    };

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    togglePassword(value) {
        this.setState({ passwordShown: value });
    };

    handleLoginClick = async (e) => {
        try {

            e.preventDefault();


            let fields = this.state.fields;
            //Mobile No
            if (!fields["username"]) {
                this.props.alert.error("User Name cannot be empty");
                document.getElementById('input-user-name').focus();
                this.setState({ loading: false });
                return false;
            }
            else if (!fields["password"]) {
                this.props.alert.error("Password cannot be empty");
                document.getElementById('input-password').focus();
                this.setState({ loading: false });
                return false;
            }

            this.setState({ btnDisabled: true, btnLoginText: "Logging in..." });

            let postObj = {
                Password: this.state.fields["password"],
                UserName: this.state.fields["username"],
                RequestIP: "0.0.0.1"
            }

            const encodedCredentials = btoa(`${Config.invAPIUserName}:${Config.invAPIPassword}`);

            await fetch(Config.baseAPIUrl + 'identity/login', {
                method: 'POST',
                headers: {
                    Authorization: 'Basic ' + encodedCredentials,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    // console.log(response);
                    if (response.IsSuccessful) {
                        this.props.alert.success(response.Message);

                        sHelpers.secureStorage.setItem('inv.user.name', response.LoginDetails.UserName);
                        sHelpers.secureStorage.setItem('inv.user.role.name', response.LoginDetails.UserRoleName);
                        sHelpers.secureStorage.setItem('inv.organization.name', response.LoginDetails.OrganizationName);
                        sHelpers.secureStorage.setItem('inv.organization.id', response.LoginDetails.OrganizationId);
                        sHelpers.secureStorage.setItem('inv.user.stores', response.LoginDetails.Stores);
                        sHelpers.secureStorage.setItem('inv.user.properties', response.LoginDetails.Properties);
                        sHelpers.secureStorage.setItem('inv.user.departments', response.LoginDetails.Departments);
                        sHelpers.secureStorage.setItem('inv.run.date', response.LoginDetails.RunDate);
                        sHelpers.secureStorage.setItem('inv.server.date', response.LoginDetails.ServerDate);
                        sHelpers.secureStorage.setItem('inv.max.run.date', response.LoginDetails.MaxRunDate);
                        sHelpers.secureStorage.setItem('inv.user.loggedin', true);
                        sHelpers.secureStorage.setItem('inv.rights.property.code', response.LoginDetails.PropertyCodes);
                        sHelpers.secureStorage.setItem('inv.ismain.user', response.LoginDetails.IsMainUser);
                        sHelpers.secureStorage.setItem('inv.lic.name', response.LoginDetails.LicenseName);
                        // set Loggedin date
                        var today = new Date(), date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
                        sHelpers.secureStorage.setItem('inv.user.loggedin.date', date);

                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/dashboard');
                        }, Config.averageMsgTimeout);
                    }
                    else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.Message);
                        this.setState({ btnDisabled: false, btnLoginText: "Loggin" });
                    }
                })
                .catch(err => {
                    this.setState({ btnDisabled: false, btnLoginText: "Login" });
                    console.log(err);
                    // this.setState({ loading: false });
                    this.props.alert.error(err.message);

                    // return;
                });
        }
        catch (error) {
            console.error('Fetch error:', error);
            // You can set an error state here or handle it as needed
            throw error;
        }

    }

    render() {
        const { paddingTop, paddingBottom } = this.state;
        return (
            <>
                {/* {this.state.loading && <LoadingAnimation />} */}
                {/* <Header></Header> */}

                <main className="container-fluid h-100"
                    ref={this.mainRef}
                    style={{
                        paddingTop: `${paddingTop}px`,
                        paddingBottom: `${paddingBottom}px`
                    }}
                >
                    <div className="row h-100 overflow-auto">
                        <div className="col-12 text-center mb-auto px-0">
                            <header className="header">
                                <div className="row">
                                    <div className="col-auto"></div>
                                    <div className="col">
                                        <div className="logo-large">
                                            <img src="/lucid-inventory-text.png" alt="" />
                                            {/* <h5>LUCID Inventory</h5> */}
                                        </div>
                                    </div>
                                    <div className="col-auto"></div>
                                </div>
                            </header>
                        </div>
                        <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center text-center py-4">
                            <h5 className="mb-4">Log in to continue</h5>
                            <form className="was-validated needs-validation" >
                                <div className="form-group form-floating mb-3 is-valid">
                                    <input type="text" className="form-control" id="input-user-name" name="input-user-name" placeholder="Username" maxLength="20" onChange={this.handleChange.bind(this, "username")} value={this.state.fields["username"]} autoFocus autoComplete='off' />
                                    <label className="form-control-label" htmlFor="input-user-name">Username</label>
                                </div>

                                <div className="form-group form-floating is-invalid mb-3">
                                    <input className="form-control " id="input-password" name="input-password" placeholder="Password" maxLength="20" type={this.state.passwordShown ? "text" : "password"} onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]} />
                                    <label className="form-control-label" htmlFor="input-password">Password</label>

                                    <button type="button" className="text-danger tooltip-btn" data-bs-toggle="tooltip"
                                        data-bs-placement="left" title="Enter valid Password" id="passworderror">
                                        {this.state.passwordShown ? <i className="bi bi-eye me-2" onClick={this.togglePassword.bind(this, false)}></i> : <i className="bi bi-eye me-2" onClick={this.togglePassword.bind(this, true)}></i>}
                                    </button>
                                </div>
                                {/* <p className="mb-3 text-center">
                                    <a href="#" className="">
                                        Forgot your password?
                                    </a>
                                </p> */}

                                <button type="button" className="btn btn-default btn-lg w-100 mb-4 shadow text-white" disabled={this.state.btnDisabled} onClick={this.handleLoginClick.bind(this)}>
                                    {this.state.btnLoginText}
                                </button>
                            </form>
                            <p className="mb-2 text-muted">Copyright © {currentYear} <span className='text-color-theme'><a href='https://lucidpos.com/restaurant-purchase-and-inventory-software/' target='_blacnk'>LUCID</a></span></p>
                            {/* <a href="#" target="_self" className="">
                                Sign up <i className="bi bi-arrow-right"></i>
                            </a> */}

                        </div>
                        <div className="col-12 text-center mt-auto">
                            <div className="row justify-content-center footer-info">
                                <div className="col-auto">
                                    {/* <p className="text-muted">Or you can continue with </p> */}
                                </div>
                                <div className="col-auto ps-0">
                                    {/* <a href="#" className="p-1"></a> */}
                                    {/* <a href="#" className="p-1"><i className="bi bi-google"></i></a>
                                    <a href="#" className="p-1"><i className="bi bi-facebook"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </>
        )
    }
}
export default withAlert()(login);