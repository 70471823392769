import React, { Component, createRef } from "react";
import { withAlert } from "react-alert"; // HOC to show the alert message
import Config, { entryMode } from "../../../data/siteconfig";
import sHelpers from "../../../modules/helpers/statichelpers";
// import AddItemPopup from '../../../component/popup/additem';
import "./variation.scss";
import "./autocomplete.scss";
// const addItemRef = React.createRef();
// import Footer from '../../../component/footer';
import PageLoading from "../../../component/loadinganimation/pageloading";
// import Autocomplete from '../../../component/autocomplete';

class variationentry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			paddingTop: 0,
			paddingBottom: 0,
			propertyList: [],
			storeList: [],
			selectedPropertyCode: 0,
			selectedPropertyName: "",
			selectedStoreCode: 0,
			selectedStoreName: "",
			variationNo: 0,
			displayVariationNo: 0,
			variationDate: "",
			variationTime: "",
			storeItems: [],
			selectedItems: [],
			fields: {},
			btnDisabled: false,
			btnVisible: true,
			btnDeleteVisible: false,
			ctrlDisabled: false,
			selectedItemCode: 0,
			selectedItemUnits: "",
			selectedItemStock: 0,
			selectedItemRate: 0,
			filteredSuggestions: [],
			itemInputValue: "",
			selectedItemValue: "",
			mode: "-",
			selectionStoreVisible: true,
			selectionItemVisible: true

			// fromDate: moment(new Date()).format("yyyy-MM-DD"),
			//  toDate: moment(new Date()).format("yyyy-MM-DD")
		};
		this.mainRef = createRef();
		this.headerRef = createRef();
		this.footerRef = createRef();
		this.itemSearchRef = createRef();
		this.qtyRef = createRef();
		this.enteredByRef = createRef();
	}

	updateStyles = () => {
		const windowHeight = window.innerHeight;
		const headerHeight = this.headerRef.current
			? this.headerRef.current.offsetHeight
			: 0;
		const footerHeight = this.footerRef.current
			? this.footerRef.current.offsetHeight
			: 0;

		if (this.mainRef.current) {
			this.mainRef.current.style.minHeight = `${windowHeight}px`;
			this.setState({
				paddingTop: headerHeight + 10,
				paddingBottom: footerHeight + 10,
			});
		}
	};

	componentDidMount() {
		this.updateStyles();
		window.addEventListener("resize", this.updateStyles);

		if (!sHelpers.LoggedinInputValidation()) {
			this.props.alert.error("Session Expired! Please log in again.");
			this.setState({ loading: true });
			setTimeout(() => {
				this.props.history.push("/login");
			}, Config.minMsgTimeout);
			return false;
		}

		if (!this.props.location.state) {
			setTimeout(() => {
				this.props.history.push("/variation");
			}, Config.minMsgTimeout);
		}

		this.setState({
			propertyList: sHelpers.secureStorage.getItem("inv.user.properties"),
		});

		if (sHelpers.secureStorage.getItem("inv.user.properties").length > 0) {
			let variationDate = sHelpers.secureStorage.getItem(
				"inv.user.properties"
			)[0].RunDate;
			const _filterStore = sHelpers.secureStorage
				.getItem("inv.user.stores")
				.filter(
					(s) =>
						s.PropertyCode ===
						sHelpers.secureStorage.getItem("inv.user.properties")[0]
							.PropertyCode
				);

			this.setState({
				storeList: _filterStore,
				variationDate: variationDate,
				selectedPropertyCode: sHelpers.secureStorage.getItem(
					"inv.user.properties"
				)[0].PropertyCode,
				selectedPropertyName: sHelpers.secureStorage.getItem(
					"inv.user.properties"
				)[0].PropertyName,
			});

			if (_filterStore.length > 0) {
				this.setState({
					selectedStoreCode: _filterStore[0].StoreCode,
					selectedStoreName: _filterStore[0].StoreName,
				});
				this.getStoreItems(
					sHelpers.secureStorage.getItem("inv.user.properties")[0].PropertyCode,
					_filterStore[0].StoreCode
				);
			}
		}


		if (this.props.location.state) {
			this.setState({ mode: this.props.location.state.mode });
			this.setState({ variationNo: this.props.location.state.variationNo });
			setTimeout(() => {
				if (this.props.location.state.mode === entryMode.view || this.props.location.state.mode === entryMode.delete) {
					this.getVariation(this.props.location.state.variationNo);
				}
				this.handleMode();
			}, Config.minMsgTimeout);
		}



		// handleMode
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateStyles);

	};

	getStoreItems = async (propertyCode, storeCode) => {
		try {
			this.setState({ storeItems: [] });
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl +
				"common/storeitems?propertycode=" +
				propertyCode +
				"&storecode=" +
				storeCode +
				"&requestip=" +
				"0.0.0.1",
				{
					method: "GET",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//  console.log(response);
					if (response.IsSuccessful) {
						sHelpers.secureStorage.setItem("inv.store.items", response.Items);
						this.setState({ storeItems: response.Items });
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
						sHelpers.secureStorage.setItem("inv.store.items", []);
					}
				})
				.catch((err) => {
					console.log(err);
					// this.setState({ loading: false });
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			// You can set an error state here or handle it as needed
			throw error;
		}
	};

	handleChangeProperty(event) {
		// setSelectedValue(event.target.value);
		// const _propertyList = sHelpers.secureStorage.getItem("inv.user.properties");
		const _filterStore = sHelpers.secureStorage
			.getItem("inv.user.stores")
			.filter((s) => s.PropertyCode === Number(event.target.value));
		const _filterproperty = sHelpers.secureStorage
			.getItem("inv.user.properties")
			.filter((p) => p.PropertyCode === Number(event.target.value));
		// console.log("Selected value:", event.target.value);
		// console.log(_propertyList);
		this.setState({
			storeList: _filterStore,
			variationDate:
				_filterproperty.length > 0 ? _filterproperty[0].RunDate : "",
			selectedPropertyCode: event.target.value,
			selectedPropertyName:
				_filterproperty.length > 0 ? _filterproperty[0].PropertyName : "",
			selectedItems: [],
			loading: true,
		});

		if (_filterStore.length > 0) {
			this.setState({
				selectedStoreCode: _filterStore[0].StoreCode,
				selectedStoreName: _filterStore[0].StoreName,
			});
			this.getStoreItems(event.target.value, _filterStore[0].StoreCode);
		}

		this.clearData();
		setTimeout(() => {
			this.setState({ loading: false });
		}, 1000);
	};

	handleReload() {
		window.location.reload(true);
	};

	handleAddItemClick() {
		// let remarks = this.state.fields["remarks"];
		// let enteredby = this.state.fields["enteredby"];
		let getSelectItems = this.state.selectedItems;
		let findItem = getSelectItems.filter(
			(itm) =>
				itm.itemCode === this.state.selectedItemCode &&
				itm.StoreCode === this.state.selectedStoreCode
		);

		if (findItem.length) {
			this.props.alert.error("Item already added.");
			document.getElementById("item-name").focus();
			return false;
		}

		const selectedItem = document.getElementById("item-name");
		const qty = this.state.fields["variationqty"]
			? parseFloat(this.state.fields["variationqty"])
			: 0;
		let fields = this.state.fields;

		if (!sHelpers.validateNumeric(qty)) {
			this.props.alert.error("Please enter the Valid Qty");
			document.getElementById("variation-qty").focus();
			fields["variationqty"] = "";
			this.setState({ fields });
			this.handleScrollIntoView(this.qtyRef);
			return false;
		}
		if (!this.state.selectedItemValue) {
			this.setState({ itemInputValue: "" });
			this.props.alert.error("Please select the item");
			document.getElementById("item-name").focus();
			fields["variationqty"] = "";
			this.setState({ fields });
			this.handleScrollIntoView(this.itemSearchRef);
			return false;
		}

		if (qty < 0) {
			this.props.alert.error("Please enter the valid qty");
			fields["variationqty"] = "";
			this.setState({ fields });
			window.scrollTo(0, 0);
			document.getElementById("variation-qty").focus();
			this.handleScrollIntoView(this.qtyRef);
			return false;
		}

		const stockVarQty = qty - parseFloat(this.state.selectedItemStock);

		if (stockVarQty === 0) {
			this.props.alert.error(
				"Closing Stock qty and variation qty should not be the same, Please enter the valid Qty"
			);
			document.getElementById("variation-qty").focus();
			fields["variationqty"] = "";
			this.setState({ fields });
			this.handleScrollIntoView(this.qtyRef);
			return false;
		}

		if (parseFloat(this.state.selectedItemRate) === 0) {
			this.props.alert.error("Rate must be greater than zero.");
			document.getElementById("item-name").focus();
			fields["variationqty"] = "";
			this.setState({ fields });
			this.handleScrollIntoView(this.itemSearchRef);
			return false;
		}

		let obj = {
			StoreCode: parseInt(this.state.selectedStoreCode),
			StoreName: this.state.selectedStoreName,
			ItemName: selectedItem.value.trim(),
			itemCode: parseInt(this.state.selectedItemCode),
			CStock: parseFloat(this.state.selectedItemStock),
			units: this.state.selectedItemUnits,
			Rate: parseFloat(this.state.selectedItemRate),
			VariationQty: qty.toFixed(3),
			stockVariationQty: stockVarQty.toFixed(3),
		};
		getSelectItems.push(obj);
		this.setState({
			selectedItems: getSelectItems,
			itemInputValue: "",
			selectedItemValue: "",
			filteredSuggestions: [],
			selectedItemCode: 0,
			selectedItemUnits: "",
			selectedItemStock: 0,
			selectedItemRate: 0,
			loading: true,
		});
		document.getElementById("item-name").focus();
		fields["variationqty"] = "";
		this.setState({ fields });
		setTimeout(() => {
			this.setState({ loading: false });
		}, 1000);
	};

	handleDeleteItemCick(itemCode, storeCode) {
		let getSelectItems = this.state.selectedItems;
		let index = getSelectItems.findIndex(
			(i) => i.itemCode === itemCode && i.storeCode === storeCode
		);
		getSelectItems.splice(index, 1);
		this.setState({ selectedItems: getSelectItems, loading: true });
		this.props.alert.success("Item deleted successfully!");
		document.getElementById("item-name").focus();
		this.handleScrollIntoView(this.itemSearchRef);
		setTimeout(() => {
			this.setState({ loading: false });
		}, 1000);
	};

	// handleAddItemPopup() {
	//     if (addItemRef.current) {
	//         addItemRef.current.handleAddItemPopup();
	//     }
	// };

	handleChangeStore(event) {

		const selectedStoreCode = event.target.value; // This is a string
		// Find the selected store by comparing store codes
		const selectedStore = this.state.storeList.find((store) => store.StoreCode === parseInt(selectedStoreCode, 10)); // Convert to number

		// Ensure that selectedStore exists to avoid potential errors
		if (selectedStore) {
			this.setState({
				selectedStoreCode: selectedStoreCode,  // StoreCode remains a string
				selectedStoreName: selectedStore.StoreName, // Update selectedStoreName state
				loading: true,
			});
		}

		this.setState({
			selectedItems: [],
			filteredSuggestions: [],
		});
		this.getStoreItems(this.state.selectedPropertyCode, selectedStoreCode);
		this.clearData();
		setTimeout(() => {
			this.setState({ loading: false });
		}, 1000);
	};

	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	};

	handleSaveClick = async (e) => {
		try {
			e.preventDefault();
			let fields = this.state.fields;
			if (this.state.selectedItems.length === 0) {
				this.props.alert.error("Please add atleast one item");
				document.getElementById("item-name").focus();
				this.handleScrollIntoView(this.itemSearchRef);
				return false;
			} else if (!fields["enteredby"]) {
				this.props.alert.error("Please enter the Entered By");
				document.getElementById("variation-entered-by").focus();
				// this.handleScrollIntoView(this.enteredByRef);
				return false;
			}
			// if (Array.isArray(this.state.selectedItems)) {
			//     // console.log("selectedItems is an array");
			// } else {
			//     this.props.alert.error("selectedItems is not an array");
			//     return false;
			// }

			this.setState({ btnDisabled: true, loading: true });

			let postObj = {
				propertycode: parseInt(this.state.selectedPropertyCode),
				login: sHelpers.secureStorage.getItem("inv.user.name"),
				remarks: this.state.fields["remarks"] || "",
				enteredby: this.state.fields["enteredby"] || "",
				selecteditems: this.state.selectedItems, // Make sure this is an array of objects
				requestip: "0.0.0.1",
			};

			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(Config.baseAPIUrl + "store/variations", {
				method: "POST",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(postObj),
			})
				.then((res) => res.json())
				.then((response) => {
					//  console.log(response);
					if (response.IsSuccessful) {
						this.props.alert.success(
							response.Message + " Variation No: " + response.VariationNo
						);
						setTimeout(() => {
							window.location.reload(true);
							this.setState({ selectedItems: [] });
							document.getElementById("item-name").value = "";
							let fields = this.state.fields;
							fields["variationqty"] = "";
							fields["remarks"] = "";
							fields["enteredby"] = "";
							this.setState({ fields });
						}, Config.averageMsgTimeout);
					} else {
						if (response.message) {
							this.props.alert.error(response.Message);
						} else {
							this.props.alert.error(response[0]);
						}
						this.setState({ btnDisabled: false, loading: false });
					}
				})
				.catch((err) => {
					console.log(err);
					this.setState({ btnDisabled: false, loading: false });
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			// You can set an error state here or handle it as needed
			throw error;
		}
	};

	handleItemSelect = (value, itemCode, units, cstock, rate) => {
		// setInputValue(value);
		//  setItemCode(itemCode);
		// setUnits(units);
		// setCStock(cstock);
		//  setRate(rate);
		this.setState({
			filteredSuggestions: [],
			itemInputValue: value,
			selectedItemValue: value,
			selectedItemCode: itemCode,
			selectedItemUnits: units,
			selectedItemStock: cstock,
			selectedItemRate: rate,
			loading: true,
		});
		let fields = this.state.fields;
		fields["variationqty"] = "";
		this.setState({ fields });
		document.getElementById("variation-qty").focus();
		setTimeout(() => {
			this.setState({ loading: false });
		}, 1000);
	};

	handleItemChange = (event) => {
		const inputValue = event.target.value;

		// Filter suggestions based on input value
		const filteredSuggestions = this.state.storeItems.filter((suggestion) =>
			suggestion.ItemName.toLowerCase().includes(inputValue.toLowerCase())
		);

		this.setState({
			itemInputValue: inputValue,
			filteredSuggestions: filteredSuggestions,
		});
		this.setState({
			selectedItemValue: "",
			selectedItemCode: 0,
			selectedItemUnits: "",
			selectedItemStock: 0,
			selectedItemRate: 0,
		});
	};

	handleItemFocus = (event) => {
		event.target.select();
	};

	handleMode() {
		if (entryMode.view === this.state.mode) {
			this.setState({
				btnVisible: false, ctrlDisabled: true, selectionStoreVisible: false,
				selectionItemVisible: false
			});
			this.handleScrollIntoView(this.headerRef);

		}
		if (entryMode.delete === this.state.mode) {
			this.setState({
				btnDeleteVisible: true,
				btnVisible: false,
				ctrlDisabled: true,
				selectionStoreVisible: false,
				selectionItemVisible: false
			});
			this.handleScrollIntoView(this.headerRef);
		} else this.clearData();
	};

	clearData() {
		let fields = this.state.fields;
		this.setState({
			selectedItems: [],
			itemInputValue: "",
			selectedItemValue: "",
			filteredSuggestions: [],
			selectedItemCode: 0,
			selectedItemUnits: "",
			selectedItemStock: 0,
			selectedItemRate: 0,
		});
		if (!(this.props.location.state.mode === "View" || this.props.location.state.mode === "Delete")) {
			this.itemSearchRef.current.value = "";
			this.itemSearchRef.current.focus();
		}

		fields["variationqty"] = "";
		this.setState({ fields });
	};

	goBack() {
		this.props.history.push("/variation");
	};

	handleScrollIntoView(scrollRef) {
		window.scrollTo(0, 0);
		setTimeout(() => {
			scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}, Config.averageMsgTimeout);
	};
	// Added by vijay 
	getVariation = async (variationNo) => {
		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(Config.baseAPIUrl + "store/variations/" + variationNo, {
				method: "GET",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((response) => {
					console.log("getvariation", response);
					if (response.IsSuccessful) {
						// Store Detail values in the state for rendering
						//	 this.setState({ variationHeader: response.Header });
						this.setState({ selectedItems: response.Detail, selectedPropertyName: response.Header.PropertyName, selectedPropertyCode: response.Header.PropertyCode });
						this.setState({
							variationDate: response.Header.VariationDate + ' ' + response.Header.VariationTime,
							displayVariationNo: response.Header.DisplayVariationNo,
							fields: { ...this.state.fields, remarks: response.Header.Remarks, enteredby: response.Header.EnteredBy },
						});

					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};
	// Added by vijay 
	handleDeleteClick = async () => {

		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(Config.baseAPIUrl + "store/variations/" + this.props.location.state.variationNo + "/" + sHelpers.secureStorage.getItem('inv.user.name'), {
				method: "DELETE",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((response) => {
					if (response.IsSuccessful) {
						this.props.alert.success(response.Message);
						setTimeout(() => {
							this.goBack();
						}, 2000);
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
						setTimeout(() => {
							this.goBack();
						}, 2000);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	render() {
		const { paddingTop, paddingBottom, mode } = this.state;

		return (
			<>
				<PageLoading />
				<main
					className="h-100"
					ref={this.mainRef}
					style={{
						paddingTop: `${paddingTop}px`,
						paddingBottom: `${paddingBottom}px`,
					}}
				>
					<header ref={this.headerRef} className="header position-fixed">
						<div className="row">
							<div className="col-auto">
								<button
									className="btn btn-light btn-44 back-btn"
									onClick={this.goBack.bind(this)}
								>
									<i className="bi bi-arrow-left"></i>
								</button>
							</div>
							<div className="col align-self-center text-center">
								<h5>Variation</h5>
							</div>
							{/* <div className="col-auto">
                    <a href="notifications.html" target="_self" className="btn btn-light btn-44">
                        <i className="bi bi-bell"></i>
                        <span className="count-indicator"></span>
                    </a>
                </div> */}
						</div>
					</header>

					<div className="main-container container">
						<div className="row mb-3">
							<div className="col">
								<h6 className="title">Variation Entry</h6>
							</div>
							<div className="col-auto align-self-center">
								<span className="tag bg-primary text-white border-0 py-1 px-2 float-end mt-1">
									{mode}
								</span>
								{/* <span href="#" className="small text-color-theme mb-0">New</span> */}
							</div>
						</div>
						<div className="row mb-2">
							<div className="col">
								<button
									className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
									onClick={this.handleReload.bind(this)}
									disabled={this.state.btnDisabled}
									style={{ display: this.state.btnVisible ? "block" : "none" }}
								>
									<i className="bi bi-reply-fill me-2"></i>Reset
								</button>
							</div>
							<div className="col">
								<button
									className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
									onClick={this.handleSaveClick.bind(this)}
									disabled={this.state.btnDisabled}
									style={{ display: this.state.btnVisible ? "block" : "none" }}
								>
									<i className="bi bi-check-lg me-2"></i> Save
								</button>
							</div>
							{this.state.btnDeleteVisible && (
								<div className="col">
									<button
										className="btn btn-danger shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
										onClick={this.handleDeleteClick.bind(this)}
									>
										<i className="bi bi-trash me-2"></i> Delete
									</button>
								</div>
							)}
						</div>
						<div className="row mb-4">
							<div className="col-auto">
								<div className="avatar avatar-44 rounded-10 shadow-sm">
									<i className="bi bi-calendar-range size-22"></i>
								</div>
							</div>
							<div className="col align-self-center ps-0">
								<h6>{this.state.variationDate}</h6>
								<p className="mb-1 text-muted">Date</p>
							</div>
							{this.state.displayVariationNo > 0 &&
								<div class="col align-self-center text-center text-end">
									<h6>{this.state.displayVariationNo}</h6>
									<p className="mb-1 text-muted">Variation No</p>
								</div>
							}

						</div>
						<div className="row h-100">
							<div className="col-12 col-md-6 col-lg-4">
								<div className="form-floating mb-3 ">
									<select
										className="form-control"
										id="variation-properties"
										onChange={this.handleChangeProperty.bind(this)}
										disabled={this.state.ctrlDisabled}
										value={this.state.selectedPropertyCode}

									>
										{/* <option value="0" selected> Select Property</option> */}
										{this.state.propertyList.map((prop) => (
											<option value={prop.PropertyCode}>
												{prop.PropertyName}
											</option>
										))}
										{/* <option value="Property-1" >Property-1</option>
                                        <option value="Property-2" selected> Property-2</option>
                                        <option value="Property-3" > Property-3</option>
                                        <option value="Property-4" >Property-4</option>
                                        <option value="Property-5" >Property-5</option> */}
									</select>
									<label htmlFor="country">Property</label>
								</div>
							</div>

							<div className="col-12 col-md-6 col-lg-4">
								<div className="form-group form-floating  mb-3">
									<input
										type="text"
										className="form-control"
										placeholder="Enter Remarsk"
										id="variation-remarks"
										onChange={this.handleChange.bind(this, "remarks")}
										value={this.state.fields["remarks"]}
										maxLength="30"
										disabled={this.state.ctrlDisabled}
									/>
									<label htmlFor="variation-remarks">Remarks</label>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4">
								<div className="form-group form-floating is-invalid mb-3">
									<input
										ref={this.enteredByRef}
										type="text"
										className="form-control"
										placeholder="Entered By"
										id="variation-entered-by"
										onChange={this.handleChange.bind(this, "enteredby")}
										value={this.state.fields["enteredby"]}
										maxLength="30"
										disabled={this.state.ctrlDisabled}
									/>
									<label htmlFor="variation-entered-by">Entered By</label>
								</div>
							</div>
							{this.state.selectionStoreVisible && (
								<div className="col-12 col-md-6 col-lg-4">
									<div className="form-floating mb-3 ">
										<select
											className="form-control "
											id="variation-stores"
											onChange={this.handleChangeStore.bind(this)}
											disabled={this.state.ctrlDisabled}
										>
											{this.state.storeList.map((store) => (
												<option value={store.StoreCode}>{store.StoreName}</option>
											))}
										</select>
										<label htmlFor="variation-properties">Store</label>
									</div>
								</div>
							)}
						</div>
						<div className="row">

							{this.state.selectionItemVisible && (
								<>
									<div className="col-7">
										<div className="form-group form-floating mb-2 shadow-sm">
											{/* <input type="text" className="form-control" id="item-name" placeholder="Search" />
                                    <label className="form-control-label" htmlFor="search">Search Item</label> */}
											{/* <button type="button" className="text-color-theme tooltip-btn">
                                        <i className="bi bi-search"></i>
                                    </button> */}
											{/* <Autocomplete suggestions={this.state.storeItems} /> */}

											<div className="form-group form-floating">
												<input
													ref={this.itemSearchRef}
													className="autocomplete-input form-control"
													type="text"
													value={this.state.itemInputValue || ""}
													onChange={this.handleItemChange}
													onFocus={this.handleItemFocus}
													id="item-name"
													autoComplete="off"
													//  dataitemcode={itemCode}
													// datacstock={cStock}
													// dataunits={units}
													// datarate={rate}
													disabled={this.state.ctrlDisabled}
												/>

												<label htmlFor="item-name">Item Search</label>
											</div>

											<ul className="autocomplete-suggestions">
												{this.state.filteredSuggestions.map((suggestion, index) => (
													<li
														key={index}
														className="autocomplete-suggestion"
														onClick={() =>
															this.handleItemSelect(
																suggestion.ItemName,
																suggestion.ItemCode,
																suggestion.Units,
																suggestion.CurrentStock,
																suggestion.LatestRate
															)
														}
													>
														{suggestion.ItemName}
													</li>
												))}
											</ul>
										</div>
									</div>

									<div className="col-5">
										<input
											ref={this.qtyRef}
											type="text"
											className="trasparent-input text-end variation-entry-qty"
											id="variation-qty"
											placeholder="0.00"
											onChange={this.handleChange.bind(this, "variationqty")}
											value={this.state.fields["variationqty"]}
											maxLength="10"
											autoComplete="off"
											disabled={this.state.ctrlDisabled}
											inputmode="numeric"
										/>
									</div>

									<div className="col-12 mb-2">
										<span className="text-muted size-12 me-2">
											Units:{" "}
											<span className="text-color-theme size-12 small">
												{this.state.selectedItemUnits}
											</span>{" "}
										</span>
										<span className="text-muted size-12 me-2">
											Rate:{" "}
											<span className="text-color-theme size-12 small">
												{this.state.selectedItemRate}
											</span>{" "}
										</span>
										<span className="text-muted size-12">
											C.Stock:{" "}
											<span className="text-color-theme size-12 small">
												{this.state.selectedItemStock}
											</span>{" "}
										</span>
									</div>
								</>
							)}

							<div className="col-12">
								{/* <button className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100" onClick={this.handleAddItemPopup.bind(this)}> */}
								<button
									className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
									onClick={this.handleAddItemClick.bind(this)}
									disabled={this.state.btnDisabled}
									style={{ display: this.state.btnVisible ? "block" : "none" }}
								>
									<i className="bi bi-plus-lg me-2"></i> Add Item
								</button>
							</div>
						</div>
						{/* <div className="row mb-4">
							<div className="card shadow-sm mb-4">
								<div className="col-12 px-0">
									<ul className="list-group list-group-flush bg-none">
										{this.state.selectedItems.map((item, id) => (
											<li className="list-group-item" id={"itm-sl-" + id}>
												<div className="row">
													<div className="col-auto align-self-center text-end">
														<p className="text-color-theme mb-0">
															{item.ItemName}
														</p>
														<p className="text-muted size-12">
															{item.StoreName}
														</p>
													</div>
													<div className="col align-self-center text-end">
														<p className="mb-0">{item.Units}</p>
														<p className="text-muted size-12">
															Rate: {item.Rate}
														</p>
													</div>
													<div className="col align-self-center text-end">
														<p className="mb-0">{item.variationQty}</p>
														<p className="text-muted size-12">
															C.Stock: {item.CStock}
														</p>
														<p className="text-muted size-12">
															Stk.Variation: {item.VariationQty}
														</p>
													</div>

													<div className="col-auto align-self-center">
														<button
															className="btn btn-light text-danger shadow-sm"
															onClick={this.handleDeleteItemCick.bind(
																this,
																item.itemCode,
																item.storeCode
															)}
															style={{
																display: this.state.btnVisible
																	? "block"
																	: "none",
															}}
														>
															<i className="bi bi-trash"></i>
														</button>
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div> */}

						{/* <div className="row mb-4">
							<div className="card shadow-sm mb-4">
								<div className="col-12 px-0">
									<ul className="list-group list-group-flush bg-none">
										{this.state.selectedItems.map((item, id) => (
											<li className="list-group-item" id={"itm-sl-" + id}>
												<div className="row">
													<div className="col-auto align-self-center text-left">
														<p className="text-color-theme mb-0">
															{item.ItemName}
														</p>
														<p className="text-muted size-12">
															{item.StoreName}
														</p>
													</div>
													<div className="col align-self-center text-left">
														<p className="mb-0">{item.Units}</p>
														<p className="text-muted size-12">
															Rate: {item.Rate}
														</p>
													</div>
													<div className="col align-self-center text-left">
														<p className="mb-0">{item.variationQty}</p>
														<p className="text-muted size-12">
															C.Stock: {item.CStock}
														</p>
														<p className="text-muted size-12">
															Stk.Var: {item.VariationQty}
														</p>
													</div>

													<div className="col-auto align-self-center">
														<button
															className="btn btn-light text-danger shadow-sm"
															onClick={this.handleDeleteItemCick.bind(
																this,
																item.itemCode,
																item.storeCode
															)}
															style={{
																display: this.state.btnVisible
																	? "block"
																	: "none",
															}}
														>
															<i className="bi bi-trash"></i>
														</button>
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div> */}

						<div className="row mb-4">
							<div className="card shadow-sm mb-4">
								<div className="col-12 px-0">
									<ul className="list-group list-group-flush bg-none">
										{this.state.selectedItems.map((item, id) => (
											<li className="list-group-item" id={"itm-sl-" + id}>
												<div className="row">
													<div className="col-4 align-self-center text-left">
														<p className="text-color-theme mb-0">
															{item.ItemName}
														</p>
														<p className="text-muted size-12">
															{item.StoreName}
														</p>
													</div>
													<div className="col-4 align-self-center text-left">
														<p className="mb-0">{item.Units}</p>
														<p className="text-muted size-12">
															Rate: {item.Rate}
														</p>
													</div>
													<div className="col-4 align-self-center text-left">
														<p className="mb-0">{item.variationQty}</p>
														<p className="text-muted size-12">
															C.Stock: {item.CStock}
														</p>
														<p className="text-muted size-12">
															Stk.Var: {item.VariationQty}
														</p>
													</div>

													<div className="col-auto align-self-center">
														<button
															className="btn btn-light text-danger shadow-sm"
															onClick={this.handleDeleteItemCick.bind(
																this,
																item.itemCode,
																item.storeCode
															)}
															style={{
																display: this.state.btnVisible
																	? "block"
																	: "none",
															}}
														>
															<i className="bi bi-trash"></i>
														</button>
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</main>

				{/* <AddItemPopup
                    ref={addItemRef}
                >
                </AddItemPopup> */}
			</>
		);
	}
}
export default withAlert()(variationentry);
